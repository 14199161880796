import {
  FormControl,
  MenuItem,
  Select,
  SelectChangeEvent,
} from "@mui/material";
import { useEffect, useRef, useState } from "react";
import { useData } from "../../context/data";
import { useActivityFilter } from "./context";
import { useGetActivities } from "./hooks";
import { ActivityTypeString } from "./types";

const FilterType = () => {
  const mounted = useRef(false);
  const { eventStatus } = useData();
  const { setType } = useActivityFilter();
  const { fetchData } = useGetActivities();
  const [selected, setSelected] = useState("0");

  const handleChange = (event: SelectChangeEvent) => {
    setSelected(event.target.value as string);
  };

  useEffect(() => {
    if (mounted.current) {
      const type: ActivityTypeString | null =
        selected !== "0" ? (selected as ActivityTypeString) : null;
      setType(type);
      fetchData({ type });
    }
    // avoid multiple call.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selected, setType]);

  useEffect(() => {
    mounted.current = true;
  }, []);

  return (
    <div>
      <FormControl sx={{ width: 160 }}>
        <Select value={selected} onChange={handleChange}>
          <MenuItem value="0">Alle aktiviteter</MenuItem>
          {eventStatus.map((item) => (
            <MenuItem key={item.ID} value={item.Name}>
              {item.Name}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </div>
  );
};

export default FilterType;
