import { gql, useLazyQuery } from "@apollo/client";
import { debounce } from "lodash";
import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import { Actor } from "../types";
import { useActorsDispatch } from "./actors";
import { Params, useSearch } from "./search";

const QUERY_ACTORS = gql`
  query Actors(
    $limit: Int
    $offset: Int
    $search: String
    $status: Status
    $mppqId: [ID]
    $answeredValueIds: [ID]
    $answeredValueValues: [Boolean]
    $answeredValuePreferences: [Boolean]
    $sellers: [ID]
    $automatchSwitch: Int
    $matchSwitch: Int
    $date: String
    $sort: String
    $sortBy: String
  ) {
    actors(
      limit: $limit
      offset: $offset
      search: $search
      Status: $status
      MPPQ_ID: $mppqId
      AnsweredValueIds: $answeredValueIds
      AnsweredValueValues: $answeredValueValues
      AnsweredValuePreferences: $answeredValuePreferences
      Sellers: $sellers
      AutomatchSwitch: $automatchSwitch
      MatchesSwitch: $matchSwitch
      Date: $date
      Sort: $sort
      SortBy: $sortBy
    ) {
      SYS_A_ID
      U_ID
      Firstname
      Lastname
      Username
      Street
      HouseNo
      Floor
      SideDoor
      ZipCode
      City
      Country
      PhonePrivate
      UnseenUnits
      AutoMatch
      MatchedUnitsCount
      Created
      Active
      TotalActors
      Activity {
        ID
        rolle
        Created
        Seller {
          Navn
        }
        Campaign {
          Name
          Date
          Time
        }
        ChangedBy {
          Navn
        }
      }
      Answers {
        MPPQ_ID
        UserFavorite
      }
    }
  }
`;

type VariablesType = {
  limit: number;
  offset: number;
  mppqId?: string[];
  answeredValueIds?: string[];
  answeredValueValues?: boolean[];
  answeredValuePreferences?: boolean[];
  search: string;
  sellers?: string[];
  status?: string;
  // automatch: boolean;
  // numberOfMatches: number;
  // numberOfUnseenMatches: number;
  date?: string;
  automatchSwitch: number | null;
  matchSwitch: number | null;
  sortBy?: string;
  sort?: "DESC" | "ASC";
};

type SearchActorsContextType = {
  fetch: () => void;
  init: boolean;
  loading: boolean;
};

const SearchActorsContext = createContext<SearchActorsContextType | undefined>(
  undefined
);

const SearchActorsProvider: React.FC = ({ children }) => {
  const isMounted = useRef(false);

  const { dispatch } = useActorsDispatch();
  const { params } = useSearch();

  const [loading, setLoading] = useState(false);
  const [init, setInit] = useState(false);

  const [getActors, { loading: queryLoading }] = useLazyQuery<
    { actors: Actor[] },
    VariablesType
  >(QUERY_ACTORS, {
    notifyOnNetworkStatusChange: true,
    variables: {
      limit: 10,
      offset: 0,
      search: "",
      status: "",
      date: "",
      automatchSwitch: null,
      matchSwitch: null,
    },
  });

  // debounce fetch
  const debounceFetch = useRef(
    debounce((variables: VariablesType) => {
      getActors({ variables })
        .then((res) => {
          if (res.data?.actors) {
            dispatch({
              type: "reload",
              actors: res.data?.actors,
            });
          }
        })
        .finally(() => {
          setLoading(false);
        });
    }, 1000)
  ).current;

  const fetch = useCallback(() => {
    // fetch actors
    setLoading(true);
    setInit(true);
    debounceFetch(getActorsVariables(params));
  }, [debounceFetch, params]);

  useEffect(() => {
    // don't fetch on load.
    if (!isMounted.current) {
      isMounted.current = true;
      return;
    }

    fetch();
  }, [params, fetch]);

  return (
    <SearchActorsContext.Provider
      value={{ fetch, init, loading: loading || queryLoading }}
    >
      {children}
    </SearchActorsContext.Provider>
  );
};

const useSearchActors = () => {
  const context = useContext(SearchActorsContext);
  if (context === undefined) {
    throw new Error(
      "useSearchActors must be used within a SearchActorsProvider"
    );
  }
  return context;
};

// === functions
const getActorsVariables = (params: Params): VariablesType => {
  let variables: VariablesType = {
    offset: (params.page - 1) * params.perPage,
    limit: params.perPage,
    search: params.filter.text,
    automatchSwitch: params.filter.automatchSwitch,
    matchSwitch: params.filter.matchSwitch,
    sortBy: params.sortBy,
    sort: params.sort,
  };

  if (params.filter.sellers.length) {
    variables = {
      ...variables,
      sellers: params.filter.sellers,
    };
  }

  if (params.filter.status !== "") {
    variables = {
      ...variables,
      status: params.filter.status,
    };
  }

  if (params.filter.date) {
    variables = {
      ...variables,
      date: params.filter.date,
    };
  }

  if (params.filter.answers.length || params.filter.favorites.length) {
    const answerIds: string[] = [];
    const answerValues: boolean[] = [];
    const preferences: boolean[] = [];

    const questionIds = params.filter.answers.map((answer) => {
      const [qId, aId] = answer.split("_");
      answerIds.push(aId);
      answerValues.push(true);
      preferences.push(params.filter.favorites.includes(qId));
      return qId;
    });

    // preferences without values.
    params.filter.favorites
      .filter((id) => !questionIds.includes(id))
      .forEach((id) => {
        questionIds.push(id);
        answerIds.push("");
        answerValues.push(false);
        preferences.push(true);
      });

    variables = {
      ...variables,
      mppqId: questionIds,
      answeredValueIds: answerIds,
      answeredValueValues: answerValues,
      answeredValuePreferences: preferences,
    };
  }

  return variables;
};

export { SearchActorsProvider, useSearchActors };
