import "@mui/lab/themeAugmentation";

import { createTheme as createMuiTheme } from "@mui/material/styles";
import typography from "./typography";
import components from "./components";
import palette from "./palette";

const createTheme = () => {
  let theme = createMuiTheme({
    typography: typography,
    palette: palette,
    line: {
      default: "#F4F5F5",
    },
  });

  theme = createMuiTheme(theme, {
    components: components(theme),
  });
  return theme;
};

export default createTheme;

declare module "@mui/material/styles" {
  interface Theme {
    line: {
      default: React.CSSProperties["color"];
    };
  }
  interface ThemeOptions {
    line: {
      default: React.CSSProperties["color"];
    };
  }
}
