import { Box, Grid, Skeleton } from "@mui/material";
import React, { memo } from "react";

const QuestionsSkeleton = () => {
  return (
    <>
      {[1, 2].map((v) => (
        <Box key={v} mb={2.5}>
          <Skeleton variant="text" width={75} sx={{ mb: 1 }} />
          <Grid container spacing={1.5}>
            {[1, 2, 3, 4].map((v) => (
              <Grid item key={v} xs={6} sm={3} md={4} lg={3} xl={2.4}>
                <Skeleton
                  variant="rectangular"
                  height={20}
                  sx={{ borderRadius: 1 }}
                />
              </Grid>
            ))}
          </Grid>
        </Box>
      ))}
    </>
  );
};

export default memo(QuestionsSkeleton);
