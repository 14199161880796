import { gql, useLazyQuery, useMutation } from "@apollo/client";
import { Box, Divider, Grid, Typography } from "@mui/material";
import { useFormik } from "formik";
import { debounce } from "lodash";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { useActorsDispatch } from "../../context/actors";
import { useEdit } from "../../context/edit";
import { Actor } from "../../types";
import {
  FieldCity,
  FieldCountry,
  FieldEmail,
  FieldFirstName,
  FieldFloor,
  FieldHouseNumber,
  FieldLastName,
  FieldPhone,
  FieldSideDoor,
  FieldStreet,
  FieldZip,
  InactiveActive,
  UserName,
} from "../MasterDataFields";
import {
  StatusMemberAction,
  StatusNonMemberAction,
} from "./StatusMemberAction";

type ActorData = Pick<
  Actor,
  | "SYS_A_ID"
  | "Firstname"
  | "Lastname"
  | "Username"
  | "EmailPrivate"
  | "PhonePrivate"
  | "Street"
  | "HouseNo"
  | "Floor"
  | "SideDoor"
  | "ZipCode"
  | "City"
  | "Country"
  | "Active"
  | "AutoMatch"
>;

type InputData = Pick<Actor, "SYS_A_ID"> & Partial<ActorData>;

type FormValues = {
  firstName: string;
  lastName: string;
  username: string;
  phone: string;
  email: string;
  street: string;
  houseNumber: string;
  floor: string;
  sideDoor: string;
  zip: string;
  city: string;
  country: string | null;
  active: boolean;
  autoMatch: boolean;
};

const QUERY_ACTOR = gql`
  query Actor($id: ID) {
    actor(ID: $id) {
      SYS_A_ID
      Firstname
      Lastname
      Username
      EmailPrivate
      PhonePrivate
      Street
      HouseNo
      Floor
      SideDoor
      ZipCode
      City
      Country
      Active
      AutoMatch
    }
  }
`;

const MUTATION_ACTOR_DATA = gql`
  mutation Actor($actor: ActorInput) {
    Actor(actor: $actor) {
      SYS_A_ID
      Firstname
      Lastname
      Username
      EmailPrivate
      PhonePrivate
      Street
      HouseNo
      Floor
      SideDoor
      ZipCode
      City
      Country
      Active
      AutoMatch
    }
  }
`;

// optimize render.
const MasterData = () => {
  const { dispatch } = useActorsDispatch();
  const { actorId, pushProgress, popProgress } = useEdit();
  const [actor, setActor] = useState<ActorData | null | undefined>(null);
  const [inputData, setInputData] = useState<Partial<ActorData> | null>(null);
  const [active, setActive] = useState(false);
  const [disabled, setDisabled] = useState(true);

  const [getActor, { loading }] = useLazyQuery<
    { actor: ActorData | null },
    { id: string }
  >(QUERY_ACTOR);

  // gql
  const [updateData] = useMutation<
    { Actor: ActorData | null },
    { actor: InputData }
  >(MUTATION_ACTOR_DATA);

  useEffect(() => {
    if (actorId) {
      // fetch data.
      getActor({
        variables: {
          id: actorId,
        },
      }).then((res) => {
        setActor(res?.data?.actor);
        if (res?.data?.actor) {
          // update actors table
          dispatch({
            type: "update",
            actor: res?.data?.actor,
          });
          setDisabled(false);
        }
      });
    }
  }, [actorId, getActor, dispatch]);

  const formik = useFormik<FormValues>({
    initialValues: {
      firstName: actor?.Firstname ?? "",
      lastName: actor?.Lastname ?? "",
      username: actor?.Username ?? "",
      phone: actor?.PhonePrivate ?? "",
      email: actor?.EmailPrivate ?? "",
      street: actor?.Street ?? "",
      houseNumber: actor?.HouseNo ?? "",
      floor: actor?.Floor ?? "",
      sideDoor: actor?.SideDoor ?? "",
      zip: actor?.ZipCode ?? "",
      city: actor?.City ?? "",
      country: actor?.Country ?? null,
      active: actor?.Active ?? false,
      autoMatch: actor?.AutoMatch ?? false,
    },
    onSubmit: (values) => {},
    enableReinitialize: true,
  });

  // update with debounce
  const handleSubmit = useRef(
    debounce((data: InputData) => {
      setInputData(null);
      pushProgress();
      updateData({
        variables: {
          actor: data,
        },
      })
        .then((res) => {
          if (res?.data?.Actor) {
            setActive(res?.data?.Actor.Active);
            // update actors table
            dispatch({
              type: "update",
              actor: res?.data?.Actor,
            });
          }
        })
        .finally(() => {
          popProgress();
        });
    }, 1000)
  );

  useEffect(() => {
    if (actor && inputData) {
      handleSubmit.current({ SYS_A_ID: actor?.SYS_A_ID, ...inputData });
    }
  }, [actor, inputData]);

  // flush debounce on unmount
  useEffect(() => {
    const submit = handleSubmit.current;
    return () => {
      submit.flush();
    };
  }, []);

  const handleFirstName = useCallback((e: React.ChangeEvent<any>) => {
    formik.setFieldValue("firstName", e.target.value);
    setInputData((data) => ({ ...data, Firstname: e.target.value }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleLastName = useCallback((e: React.ChangeEvent<any>) => {
    formik.setFieldValue("lastName", e.target.value);
    setInputData((data) => ({ ...data, Lastname: e.target.value }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleUsername = useCallback((e: React.ChangeEvent<any>) => {
    formik.setFieldValue("username", e.target.value);
    setInputData((data) => ({ ...data, Username: e.target.value }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handlePhone = useCallback((e: React.ChangeEvent<any>) => {
    formik.setFieldValue("phone", e.target.value);
    setInputData((data) => ({ ...data, PhonePrivate: e.target.value }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleEmail = useCallback((e: React.ChangeEvent<any>) => {
    formik.setFieldValue("email", e.target.value);
    setInputData((data) => ({ ...data, EmailPrivate: e.target.value }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleStreet = useCallback((e: React.ChangeEvent<any>) => {
    formik.setFieldValue("street", e.target.value);
    setInputData((data) => ({ ...data, Street: e.target.value }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleHouseNumber = useCallback((e: React.ChangeEvent<any>) => {
    formik.setFieldValue("houseNumber", e.target.value);
    setInputData((data) => ({ ...data, HouseNo: e.target.value }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleFloor = useCallback((e: React.ChangeEvent<any>) => {
    formik.setFieldValue("floor", e.target.value);
    setInputData((data) => ({ ...data, Floor: e.target.value }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSideDoor = useCallback((e: React.ChangeEvent<any>) => {
    formik.setFieldValue("sideDoor", e.target.value);
    setInputData((data) => ({ ...data, SideDoor: e.target.value }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleZip = useCallback((e: React.ChangeEvent<any>) => {
    formik.setFieldValue("zip", e.target.value);
    setInputData((data) => ({ ...data, ZipCode: e.target.value }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleCity = useCallback((e: React.ChangeEvent<any>) => {
    formik.setFieldValue("city", e.target.value);
    setInputData((data) => ({ ...data, City: e.target.value }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleCountry = useCallback((value: string | null) => {
    formik.setFieldValue("country", value);
    setInputData((data) => ({ ...data, Country: value }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleActive = useCallback(
    (checked: boolean) => {
      formik.setFieldValue("active", checked);
      setInputData((data) => ({ ...data, Active: checked }));
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const handleAutoMatch = useCallback(
    (checked: boolean) => {
      formik.setFieldValue("autoMatch", checked);
      setInputData((data) => ({ ...data, AutoMatch: checked }));
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  return (
    <div>
      <Box py={3.5} px={3}>
        <Box mb={3}>
          <Typography variant="sectionTitle">Kontaktinformationer</Typography>
        </Box>
        <Grid container columnSpacing={2} rowSpacing={3}>
          <Grid item xs={12} sm={6} md={3}>
            <FieldFirstName
              name="firstName"
              value={formik.values.firstName}
              onChange={handleFirstName}
              disabled={loading || disabled}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <FieldLastName
              name="lastName"
              value={formik.values.lastName}
              onChange={handleLastName}
              disabled={loading || disabled}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={3} lg={2}>
            <FieldPhone
              name="phone"
              value={formik.values.phone}
              onChange={handlePhone}
              disabled={loading || disabled}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <FieldEmail
              name="email"
              value={formik.values.email}
              onChange={handleEmail}
              disabled={loading || disabled}
            />
          </Grid>
        </Grid>
      </Box>

      <Divider />

      <Box py={3.5} px={3}>
        <Box mb={2}>
          <Typography variant="sectionTitle">Adresse</Typography>
        </Box>
        <Grid container columnSpacing={2} rowSpacing={2}>
          <Grid item xs={12} sm={6} md={3}>
            <FieldStreet
              name="street"
              value={formik.values.street}
              onChange={handleStreet}
              disabled={loading || disabled}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={3} lg={2}>
            <FieldHouseNumber
              name="houseNumber"
              value={formik.values.houseNumber}
              onChange={handleHouseNumber}
              disabled={loading || disabled}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={3} lg={2}>
            <FieldFloor
              name="floor"
              value={formik.values.floor}
              onChange={handleFloor}
              disabled={loading || disabled}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={3} lg={2}>
            <FieldSideDoor
              name="sideDoor"
              value={formik.values.sideDoor}
              onChange={handleSideDoor}
              disabled={loading || disabled}
            />
          </Grid>
          <Grid item xs={12} />
          <Grid item xs={12} sm={6} md={3}>
            <FieldCity
              name="city"
              value={formik.values.city}
              onChange={handleCity}
              disabled={loading || disabled}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={3} lg={2}>
            <FieldZip
              name="zip"
              value={formik.values.zip}
              onChange={handleZip}
              disabled={loading || disabled}
            />
          </Grid>
          <Grid item xs={12} />
          <Grid item xs={12} sm={6} md={3}>
            <FieldCountry
              name="country"
              value={formik.values.country}
              onChange={handleCountry}
              disabled={loading || disabled}
            />
          </Grid>
        </Grid>
      </Box>

      <Divider />

      <Box py={3.5} px={3}>
        <Grid container columnSpacing={3} justifyContent="space-between">
          <Grid item md={4} lg={3} xs={12}>
            <Box mb={{ md: 0, xs: 2 }}>
              <Typography variant="sectionTitle" mb={1}>
                Medlemsportal
              </Typography>
              <Typography variant="sectionDesc">
                Status på om aktøren er oprettet som medlem. Det er muligt at
                oprette aktøren som medlem ved at klikke på “Opret som medlem
              </Typography>
            </Box>
          </Grid>
          <Grid item md={6} lg={7} xs={12}>
            <Box mb={3}>
              {actor && (
                <>
                  {active ? (
                    <StatusMemberAction actorId={actor.SYS_A_ID} />
                  ) : (
                    <StatusNonMemberAction actorId={actor.SYS_A_ID} />
                  )}
                </>
              )}
            </Box>
            <Box>
              <UserName
                name="username"
                value={formik.values.username}
                onChange={handleUsername}
                disabled={loading || disabled}
                fullWidth={false}
              />
            </Box>
          </Grid>
        </Grid>
      </Box>

      <Divider />

      <InactiveActive
        portal={formik.values.active}
        autoMatch={formik.values.autoMatch}
        onChangePortal={handleActive}
        onChangeAutoMatch={handleAutoMatch}
        disabled={loading || disabled}
      />
    </div>
  );
};

export default MasterData;
