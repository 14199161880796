import { ArrowDownward, ArrowUpward } from "@mui/icons-material";
import { Box, IconButton, Stack } from "@mui/material";
import { useLayout } from "../../context/layout";
import AddButton from "../add/AddButton";

const TopButtons = () => {
  const { showSearchForm, setShowSearchForm } = useLayout();

  const handleCollapse = () => {
    setShowSearchForm(!showSearchForm);
  };

  return (
    <Stack direction="row">
      <AddButton />
      <Box ml={3}>
        <IconButton onClick={handleCollapse}>
          {showSearchForm ? <ArrowUpward /> : <ArrowDownward />}
        </IconButton>
      </Box>
    </Stack>
  );
};

export default TopButtons;
