import { gql, useLazyQuery, useMutation } from "@apollo/client";
import { Box, Divider, Grid, Skeleton, Stack, Typography } from "@mui/material";
import { debounce } from "lodash";
import React, { memo, useCallback, useEffect, useRef, useState } from "react";
import { Question, useData } from "../../context/data";
import { useEdit } from "../../context/edit";
import { Actor, Answer, AnsweredValue } from "../../types";
import FavoriteIconButton from "../FavoriteIconButton";
import OptionChip from "../OptionChip";
import OptionChipStar from "../OptionChipStar";

type ActorAnswers = Pick<Actor, "SYS_A_ID" | "Answers">;

type QuestionGroupProps = {
  question: Question;
  answer?: Answer;
  disabled?: boolean;
  inlineStar?: boolean;
  update: (
    questionId: string,
    answeredValues: AnsweredValue[],
    favorite: boolean
  ) => void;
};

type AnswerInput = Omit<Answer, "UserWeight"> & {
  A_ID: string;
};

const QUERY_ACTOR = gql`
  query Actor($id: ID) {
    actor(ID: $id) {
      SYS_A_ID
      Answers {
        MPPQ_ID
        Value {
          AnsweredValues {
            ID
            Value
          }
        }
        UserFavorite
      }
    }
  }
`;

const MUTATION_ACTOR_ANSWERS = gql`
  mutation UpdateActorAnswers($question: ActorQuestionInput) {
    ActorAnswer(question: $question) {
      MPPQ_ID
      UserFavorite
      Value {
        AnsweredValues {
          ID
          Value
        }
      }
    }
  }
`;

const Profile = () => {
  const { questionGroup } = useData();
  const { actorId, popProgress, pushProgress } = useEdit();

  const [actor, setActor] = useState<ActorAnswers | null | undefined>(null);

  const [answers, setAnswers] = useState<{
    [key: string]: Answer;
  }>({});

  const [getActor] = useLazyQuery<
    { actor: ActorAnswers | null },
    { id: string }
  >(QUERY_ACTOR);

  // gql.
  const [updateAnswers] = useMutation<
    { ActorAnswer: Answer },
    { question: AnswerInput }
  >(MUTATION_ACTOR_ANSWERS);

  useEffect(() => {
    if (actorId) {
      // fetch data.
      getActor({
        variables: {
          id: actorId,
        },
      }).then((res) => {
        setActor(res?.data?.actor);
      });
    }
  }, [actorId, getActor]);

  const handleUpdate = useCallback(
    (
      questionId: string,
      answeredValues: AnsweredValue[],
      favorite: boolean
    ) => {
      if (actor) {
        pushProgress();
        updateAnswers({
          variables: {
            question: {
              A_ID: actor.SYS_A_ID,
              MPPQ_ID: questionId,
              UserFavorite: favorite,
              Value: {
                AnsweredValues: answeredValues,
              },
            },
          },
        }).finally(() => {
          // TODO: dispatch actors with Answers.UserFavorite

          popProgress();
        });
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [actor]
  );

  // update from actor.
  useEffect(() => {
    let answers: {
      [key: string]: Answer;
    } = {};

    if (actor?.Answers) {
      actor.Answers.forEach((answer) => {
        answers[answer.MPPQ_ID] = answer;
      });
    }

    setAnswers(answers);
  }, [actor]);

  return (
    <Box p={2.5}>
      <Box mb={4}>
        <Typography variant="sectionTitle" mb={1.5}>
          Boligen
        </Typography>
        {actor === null ? (
          <SkeletonFieldRow />
        ) : (
          <>
            {questionGroup &&
              questionGroup?.housing.map((question) => (
                <React.Fragment key={question.MPPQ_ID}>
                  <FieldRow
                    question={question}
                    answer={answers[question.MPPQ_ID]}
                    update={handleUpdate}
                    disabled={actor === null}
                  />
                  <Divider />
                </React.Fragment>
              ))}
          </>
        )}
      </Box>
      <Box mb={4}>
        <Typography variant="sectionTitle" mb={1.5}>
          Økonomi
        </Typography>
        {actor === null ? (
          <SkeletonFieldRow />
        ) : (
          <>
            {questionGroup &&
              questionGroup?.economy.map((question) => (
                <React.Fragment key={question.MPPQ_ID}>
                  <FieldRow
                    question={question}
                    answer={answers[question.MPPQ_ID]}
                    update={handleUpdate}
                    disabled={actor === null}
                  />
                  <Divider />
                </React.Fragment>
              ))}
          </>
        )}
      </Box>
      <Box mb={4}>
        <Typography variant="sectionTitle" mb={1.5}>
          Faciliteter
        </Typography>
        <Stack direction="row" flexWrap="wrap">
          {actor === null ? (
            <SkeletonFieldRow />
          ) : (
            <>
              {questionGroup &&
                questionGroup?.facilities.map((question) => (
                  <React.Fragment key={question.MPPQ_ID}>
                    <FieldRow
                      question={question}
                      answer={answers[question.MPPQ_ID]}
                      update={handleUpdate}
                      disabled={actor === null}
                      inlineStar={true}
                    />
                  </React.Fragment>
                ))}
            </>
          )}
        </Stack>
      </Box>
    </Box>
  );
};

const FieldRow = memo(
  ({ question, answer, disabled, update, inlineStar }: QuestionGroupProps) => {
    const [selected, setSelected] = useState<string[]>([]);
    const [favorite, setFavorite] = useState(false);

    const init = useRef(false);

    useEffect(() => {
      if (answer) {
        const selected: string[] = [];
        if (answer.Value?.AnsweredValues) {
          answer.Value.AnsweredValues.forEach((value) => {
            if (value.Value) {
              selected.push(value.ID);
            }
          });
        }

        setSelected(selected);

        setFavorite(answer.UserFavorite);
      } else {
        setSelected([]);
        setFavorite(false);
      }
    }, [answer]);

    // update with debounce
    // eslint-disable-next-line react-hooks/exhaustive-deps
    const handleSubmit = useCallback(
      debounce(
        (
          questionId: string,
          answeredValues: AnsweredValue[],
          favorite: boolean
        ) => {
          update(questionId, answeredValues, favorite);
        },
        1000
      ),
      [update]
    );

    useEffect(() => {
      if (init.current) {
        handleSubmit(
          question.MPPQ_ID,
          selected.map((value) => ({ ID: value, Value: true })),
          favorite
        );
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selected, favorite]);

    const handleClickChip = useCallback(
      (value: string) => {
        init.current = true;
        if (selected.includes(value)) {
          setSelected((selected) => selected.filter((val) => val !== value));
        } else {
          setSelected((selected) => [...selected, value]);
        }
      },
      [selected]
    );

    const handleFavoriteQuestion = useCallback(() => {
      init.current = true;
      setFavorite(!favorite);
    }, [favorite]);

    return (
      <>
        {inlineStar ? (
          <>
            {question.Properties.Options.map((option) => (
              <Box key={option.Value} px={0.75} mb={0.75}>
                <OptionChipStar
                  label={option.ShowValue}
                  selected={selected.includes(option.Value)}
                  isFavorite={favorite}
                  value={option.Value}
                  handleClick={handleClickChip}
                  handleFavorite={handleFavoriteQuestion}
                  disabled={disabled}
                  sx={{
                    minWidth: 150,
                    width: "auto",
                    justifyContent: "flex-start",
                  }}
                />
              </Box>
            ))}
          </>
        ) : (
          <Box pb={2} pt={2.75}>
            <Grid container>
              <Grid item xs={12} xl={2} lg={3}>
                <Stack
                  direction="row"
                  alignItems="center"
                  mb={{ xs: 2, lg: 0 }}
                >
                  <FavoriteIconButton
                    isActive={favorite}
                    onClick={handleFavoriteQuestion}
                  />
                  <Typography
                    variant="sectionDesc"
                    fontWeight={500}
                    fontSize={14}
                    ml={1}
                  >
                    {question.Prefix}
                  </Typography>
                </Stack>
              </Grid>
              <Grid item xs={12} xl={10} lg={9}>
                <Stack direction="row" flexWrap="wrap">
                  {question.Properties.Options.map((option) => {
                    return (
                      <Box key={option.Value} px={0.75} mb={0.75}>
                        <OptionChip
                          label={option.ShowValue}
                          value={option.Value}
                          selected={selected.includes(option.Value)}
                          handleClick={handleClickChip}
                          disabled={disabled}
                          sx={{
                            minWidth: 60,
                          }}
                        />
                      </Box>
                    );
                  })}
                </Stack>
              </Grid>
            </Grid>
          </Box>
        )}
      </>
    );
  }
);

const SkeletonFieldRow = memo(() => {
  return (
    <>
      {[1, 2, 3].map((i) => (
        <Box key={i} pb={2} pt={2.75}>
          <Grid container>
            <Grid item xs={12} xl={2} lg={3}>
              <Stack direction="row" alignItems="center" mb={{ xs: 2, lg: 0 }}>
                <Skeleton width={100} />
              </Stack>
            </Grid>
            <Grid item xs={12} xl={10} lg={9}>
              <Stack direction="row" flexWrap="wrap">
                {[1, 2, 3, 4].map((option) => {
                  return (
                    <Box key={option} px={0.75} mb={0.75}>
                      <Skeleton
                        variant="rectangular"
                        sx={{ width: 60, height: 18, borderRadius: 1 }}
                      />
                    </Box>
                  );
                })}
              </Stack>
            </Grid>
          </Grid>
        </Box>
      ))}
    </>
  );
});

export default Profile;
