import { Box, Modal, SxProps } from "@mui/material";
import React, { createContext, useCallback, useContext, useState } from "react";
import { Theme } from "@mui/material/styles";

const sxBox: SxProps<Theme> = {
  position: "absolute",
  left: "50%",
  top: "50%",
  width: "95%",
  maxWidth: "1080px",
  bgcolor: "background.paper",
  transform: "translate(-50%, -50%)",
  borderRadius: 2,
  border: "1px solid #F4F5F5",
  borderColor: "divider",
  outline: "none",
};

type ModalContextType = {
  open: (url: string) => void;
};

// === create context
const ModalContext = createContext<ModalContextType | undefined>(undefined);

// === context provider
const IframeModalProvider: React.FC = ({ children }) => {
  const [url, setUrl] = useState<string | null>(null);
  const [isOpen, setIsOpen] = useState(false);

  const open = useCallback((url) => {
    setIsOpen(true);
    setUrl(url);
  }, []);

  const handleClose = () => {
    setUrl(null);
    setIsOpen(false);
  };

  return (
    <ModalContext.Provider value={{ open }}>
      {children}
      <Modal open={isOpen} onClose={handleClose}>
        <Box sx={sxBox}>
          <Box sx={{ height: 600 }}>
            {url && (
              <iframe
                src={url}
                frameBorder="0"
                title="modal"
                height="100%"
                width="100%"
              />
            )}
          </Box>
        </Box>
      </Modal>
    </ModalContext.Provider>
  );
};

// === custom hook
const useIframeModal = () => {
  const context = useContext(ModalContext);
  if (context === undefined) {
    throw new Error("useIframeModal must be used within a IframeModalProvider");
  }
  return context;
};

export { IframeModalProvider, useIframeModal };
