import React from "react";
import TablePagination from "../../../../../components/TablePagination";
import { useActors } from "../../../context/actors";
import { useSearch } from "../../../context/search";

const Pagination = () => {
  const { params, submit } = useSearch();
  const { total } = useActors();

  const setPage = (page: number) => {
    submit({
      page,
    });
  };

  const setPerPage = (perPage: number) => {
    submit({
      page: 1,
      perPage,
    });
  };

  return (
    <TablePagination
      page={params.page}
      perPage={params.perPage}
      total={total}
      onPageChange={setPage}
      onPerPageChange={setPerPage}
    />
  );
};

export default Pagination;
