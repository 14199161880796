import { PaletteOptions } from "@mui/material/styles";

const palette: PaletteOptions = {
  primary: {
    main: "#496A70",
    contrastText: "#FFFFFF",
  },
  default: {
    main: "#ffffff",
    contrastText: "#333333",
  },
  grey: {
    "50": "#F9FAFA",
    "100": "#F4F5F5",
  },
};

export default palette;

declare module "@mui/material/styles" {
  interface Palette {
    default: Palette["primary"];
  }
  interface PaletteOptions {
    default: PaletteOptions["primary"];
  }
}
