import React, { createContext, useContext, useReducer, useState } from "react";
import { Activity, ActivityTypeString } from "./types";

// === types
type ActivityReducerType = {
  activities: Activity[];
  loading: boolean;
};

type ActivityReducerAction =
  | { type: "loading" }
  | { type: "reload"; activities: Activity[] }
  | { type: "insertMore"; activities: Activity[] }
  | { type: "update"; activity: Pick<Activity, "ABE_ID"> & Partial<Activity> }
  | { type: "seenAll"; seen: boolean }
  | { type: "remove"; id: string };

type ActivityContextType = {
  activities: Activity[];
  loading: boolean;
};

type ActivityFilterContextType = {
  type: ActivityTypeString | null;
  setType: React.Dispatch<React.SetStateAction<ActivityTypeString | null>>;
};

// === reducer
const activityReducer = (
  state: ActivityReducerType,
  action: ActivityReducerAction
): ActivityReducerType => {
  switch (action.type) {
    case "loading":
      return { loading: true, activities: [] };
    case "reload":
      return { loading: false, activities: action.activities };
    case "update":
      return {
        ...state,
        activities: state.activities.map((activity) => {
          if (activity.ABE_ID === action.activity.ABE_ID) {
            return { ...activity, ...action.activity };
          }
          return activity;
        }),
      };
    case "seenAll":
      return {
        ...state,
        activities: state.activities.map((activity) => {
          return { ...activity, Seen: action.seen };
        }),
      };
    case "insertMore":
      return {
        ...state,
        activities: [...state.activities, ...action.activities],
      };
    case "remove":
      return {
        ...state,
        activities: state.activities.filter((activity) => {
          return activity.ABE_ID !== action.id;
        }),
      };
    default:
      throw new Error(`Unhandled action type`);
  }
};

// === context
const ActivitiesContext = createContext<ActivityContextType | undefined>(
  undefined
);
const ActivitiesDispatchContext = createContext<
  | {
      dispatch: React.Dispatch<ActivityReducerAction>;
    }
  | undefined
>(undefined);

const ActivityFilterContext = createContext<
  ActivityFilterContextType | undefined
>(undefined);

const ActivitiesProvider: React.FC = ({ children }) => {
  const [type, setType] = useState<ActivityTypeString | null>(null);

  const [state, dispatch] = useReducer(activityReducer, {
    activities: [],
    loading: false,
  });

  return (
    <ActivityFilterContext.Provider value={{ type, setType }}>
      <ActivitiesContext.Provider value={state}>
        <ActivitiesDispatchContext.Provider value={{ dispatch }}>
          {children}
        </ActivitiesDispatchContext.Provider>
      </ActivitiesContext.Provider>
    </ActivityFilterContext.Provider>
  );
};

// === custom hook
const useActivities = () => {
  const context = useContext(ActivitiesContext);
  if (context === undefined) {
    throw new Error("useActivities must be used within a ActivitiesProvider");
  }
  return context;
};

const useActivitiesDispatch = () => {
  const context = useContext(ActivitiesDispatchContext);
  if (context === undefined) {
    throw new Error(
      "useActivitiesDispatch must be used within a ActivitiesProvider"
    );
  }
  return context;
};

const useActivityFilter = () => {
  const context = useContext(ActivityFilterContext);
  if (context === undefined) {
    throw new Error(
      "useActivityFilter must be used within a ActivitiesProvider"
    );
  }
  return context;
};

export {
  ActivitiesProvider,
  useActivities,
  useActivitiesDispatch,
  useActivityFilter,
};
