import {
  Box,
  Popover,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TableSortLabel,
  Typography,
} from "@mui/material";
import moment from "moment";
import React, { memo, useEffect, useState } from "react";
import { StyledTable } from "../../../../../theme/styled-components";
import { ActorActivity } from "../../../types";

/**
 * Actor status column value with campaign popup.
 */
const Status = ({ activities }: { activities: ActorActivity[] }) => {
  const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null);

  const [roles, setRoles] = useState<string[]>([]);
  const [campaigns, setCampaigns] = useState<
    {
      text: string;
      date: string;
    }[]
  >([]);

  const handlePopoverOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const openPopover = Boolean(anchorEl);

  useEffect(() => {
    let roles: string[] = [];
    let campaigns: {
      text: string;
      date: string;
    }[] = [];
    activities.forEach((activity) => {
      roles.push(activity.rolle);
      const dateMoment = moment(activity.Created);
      const date = dateMoment.isValid()
        ? dateMoment.format("YYYY-MM-DD HH:mm:ss")
        : "";
      if (activity.Campaign) {
        campaigns.push({
          text: activity.Campaign?.Name,
          date,
        });
      }
    });
    roles = roles.filter((value, index, self) => self.indexOf(value) === index);

    setRoles(roles);
    setCampaigns(campaigns);
  }, [activities]);

  return (
    <>
      {roles.map((role, i) => (
        <div key={i}>
          {role}
          {role === "Lead" && (
            <>
              <Typography
                component="span"
                fontSize={13}
                onClick={handlePopoverOpen}
                color="#7BB3BD"
                sx={{
                  cursor: "pointer",
                }}
              >
                &nbsp;(se historik)
              </Typography>
            </>
          )}
        </div>
      ))}
      <Popover
        open={openPopover}
        anchorEl={anchorEl}
        onClose={handlePopoverClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
      >
        <Box sx={{ p: 2, pb: 0, width: 320 }}>
          <Typography fontWeight={500}>Lead overblik</Typography>
        </Box>
        <StyledTable>
          <TableHead>
            <TableRow>
              <TableCell>
                <TableSortLabel>Kampagne</TableSortLabel>
              </TableCell>
              <TableCell>
                <TableSortLabel>Dato</TableSortLabel>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {campaigns.map((campaign, i) => (
              <TableRow key={i}>
                <TableCell>{campaign.text}</TableCell>
                <TableCell>{campaign.date}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </StyledTable>
      </Popover>
    </>
  );
};

export default memo(Status);
