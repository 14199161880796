import { gql, useLazyQuery, useMutation } from "@apollo/client";
import { AccountCircleOutlined, Add, MoreVert } from "@mui/icons-material";
import CloseIcon from "@mui/icons-material/Close";
import {
  Box,
  Button,
  Divider,
  FormControl,
  Grid,
  IconButton,
  Menu,
  MenuItem,
  Select,
  SelectChangeEvent,
  Skeleton,
  Snackbar,
  Stack,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";
import { format, parseISO } from "date-fns";
import React, {
  createContext,
  memo,
  useContext,
  useEffect,
  useReducer,
  useState,
} from "react";
import TablePagination from "../../../../components/TablePagination";
import { StyledTable } from "../../../../theme/styled-components";
import { useData } from "../../context/data";
import { useEdit } from "../../context/edit";
import { Actor, ActorNote } from "../../types";

type ActorNotes = Pick<Actor, "SYS_A_ID" | "Notes">;

type NoteInput = {
  ID: string;
  Header: string;
  Note: string;
  Category: string;
  Delete: boolean;
};

// gql.
const QUERY_ACTOR = gql`
  query Actor($id: ID) {
    actor(ID: $id) {
      SYS_A_ID
      Notes {
        SYS_N_ID
        Created
        Af
        Overskrift
        Note
        Category {
          SYS_KAT_ID
          DK_Name
        }
      }
    }
  }
`;

const MUTATION_NOTE = gql`
  mutation CreateNote($actor: ActorInput) {
    Actor(actor: $actor) {
      SYS_A_ID
      Notes {
        SYS_N_ID
        Created
        Af
        Overskrift
        Note
        Category {
          SYS_KAT_ID
          DK_Name
        }
      }
    }
  }
`;

// reducer and context.
type ReducerAction =
  | { type: "reload"; data: ActorNote[] }
  | { type: "add"; data: ActorNote }
  | { type: "update"; data: ActorNote }
  | { type: "delete"; id: string };

const reducer = (state: ActorNote[], action: ReducerAction): ActorNote[] => {
  switch (action.type) {
    case "reload":
      return action.data;
    case "add":
      return [...state, action.data];
    case "update":
      return state.map((note) => {
        return note.SYS_N_ID === action.data.SYS_N_ID ? action.data : note;
      });
    case "delete":
      return state.filter((note) => note.SYS_N_ID !== action.id);
    default:
      return state;
  }
};

// reducer actions.
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const addNote = (note: ActorNote): ReducerAction => ({
  type: "add",
  data: note,
});

const deleteNote = (id: string): ReducerAction => ({
  type: "delete",
  id,
});

const updateNote = (note: ActorNote): ReducerAction => ({
  type: "update",
  data: note,
});

const NotesContext = createContext<{
  notes: ActorNote[];
  dispatch: React.Dispatch<ReducerAction>;
  showMsg: (msg: string) => void;
}>({
  notes: [],
  dispatch: () => {},
  showMsg: () => {},
});

const useNotes = () => useContext(NotesContext);

const Remark = () => {
  const { actorId } = useEdit();

  const [actor, setActor] = useState<ActorNotes | null | undefined>(null);

  // local state.
  const [notes, dispatch] = useReducer(reducer, []);

  const [getActor] = useLazyQuery<{ actor: ActorNotes | null }, { id: string }>(
    QUERY_ACTOR
  );

  useEffect(() => {
    if (actorId) {
      // fetch data.
      getActor({
        variables: {
          id: actorId,
        },
      }).then((res) => {
        setActor(res?.data?.actor);
      });
    }
  }, [actorId, getActor]);

  useEffect(() => {
    if (actor && actor.Notes) {
      dispatch({ type: "reload", data: actor.Notes });
    }
  }, [actor]);

  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(10);

  const [openNew, setOpenNew] = useState(false);

  const handleCloseNew = () => {
    setOpenNew(false);
  };

  const handleOpenNew = () => {
    setOpenNew(true);
  };

  const [openMsg, setOpenMsg] = useState(false);
  const [msg, setMsg] = useState("");

  const handleCloseMsg = () => {
    setOpenMsg(false);
    setMsg("");
  };

  const showMsg = (msg: string) => {
    setMsg(msg);
    setOpenMsg(true);
  };

  return (
    <NotesContext.Provider
      value={{
        notes,
        dispatch,
        showMsg,
      }}
    >
      <Box p={2.5}>
        <Stack
          direction={{ xs: "column", sm: "row" }}
          justifyContent="space-between"
          spacing={2}
          mb={1.5}
        >
          <div>
            <Typography variant="sectionTitle">Bemærkninger</Typography>
          </div>
          <div>
            <Button
              variant="contained"
              startIcon={<Add />}
              onClick={handleOpenNew}
            >
              TILFØJ BEMÆRKNING
            </Button>
          </div>
        </Stack>

        {/* table */}
        <TableContainer>
          <StyledTable>
            <TableHead>
              <TableRow>
                <TableCell>Dato</TableCell>
                <TableCell>Kategori</TableCell>
                <TableCell>Bemærkning</TableCell>
                <TableCell>Ansvarlig</TableCell>
                <TableCell></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {openNew && (
                <TableRow>
                  <TableCell colSpan={5}>
                    <NoteForm closeForm={handleCloseNew} />
                  </TableCell>
                </TableRow>
              )}
              {actor === null ? (
                <>
                  {[1, 2, 3].map((i) => (
                    <NoteRowSkeleton key={i} />
                  ))}
                </>
              ) : (
                <>
                  {notes
                    .slice((page - 1) * perPage, page * perPage)
                    .map((note, i) => (
                      <NoteRow key={note.SYS_N_ID} note={note} />
                    ))}
                </>
              )}
            </TableBody>
          </StyledTable>
        </TableContainer>

        {/* static pagination */}
        <TablePagination
          total={notes.length}
          page={page}
          perPage={perPage}
          onPageChange={setPage}
          onPerPageChange={setPerPage}
        />
      </Box>

      <Snackbar
        open={openMsg}
        autoHideDuration={6000}
        onClose={handleCloseMsg}
        message={msg}
        action={
          <IconButton
            size="small"
            aria-label="close"
            color="inherit"
            onClick={handleCloseMsg}
          >
            <CloseIcon fontSize="small" />
          </IconButton>
        }
      />
    </NotesContext.Provider>
  );
};

const NoteRow = memo(({ note }: { note: ActorNote }) => {
  const [edit, setEdit] = useState(false);
  const [confirmDelete, setConfirmDelete] = useState(false);

  const handleOpenEdit = () => setEdit(true);
  const handleCloseEdit = () => setEdit(false);
  const handleOpenDelete = () => setConfirmDelete(true);
  const handleCloseDelete = () => setConfirmDelete(false);

  return (
    <TableRow>
      <>
        {!edit ? (
          <>
            <TableCell>
              {note.Created && format(parseISO(note.Created), "MMMM d, yyyy")}
            </TableCell>
            <TableCell>{note.Category?.DK_Name}</TableCell>
            <TableCell>
              <Typography fontSize={13} fontWeight={500}>
                {note.Overskrift}
              </Typography>
              <Typography fontSize={12}>{note.Note}</Typography>
            </TableCell>
            <TableCell>
              <Stack direction="row">
                <AccountCircleOutlined />
                NoteRowSkeleton
                <Typography ml={1} fontSize={14}>
                  {note.Af}
                </Typography>
              </Stack>
            </TableCell>
            <TableCell align="right" width={120}>
              {confirmDelete ? (
                <NoteDelete data={note} closeForm={handleCloseDelete} />
              ) : (
                <ItemMenu
                  openEdit={handleOpenEdit}
                  openDelete={handleOpenDelete}
                />
              )}
            </TableCell>
          </>
        ) : (
          <>
            <TableCell colSpan={5}>
              <NoteForm data={note} closeForm={handleCloseEdit} />
            </TableCell>
          </>
        )}
      </>
    </TableRow>
  );
});

const NoteRowSkeleton = () => (
  <TableRow>
    <TableCell>
      <Skeleton variant="text" />
    </TableCell>
    <TableCell>
      <Skeleton variant="text" />
    </TableCell>
    <TableCell>
      <Skeleton variant="text" />
    </TableCell>
    <TableCell>
      <Skeleton variant="text" />
    </TableCell>
    <TableCell align="right" width={120}></TableCell>
  </TableRow>
);

const NoteForm = ({
  data,
  closeForm,
}: {
  data?: ActorNote;
  closeForm: () => void;
}) => {
  const { noteCategories } = useData();
  const { dispatch, showMsg } = useNotes();
  const { actorId } = useEdit();

  // gql
  const [updateData, { loading }] = useMutation<
    { Actor: { Notes: ActorNote[] } },
    { actor: { SYS_A_ID: string; Notes: Partial<NoteInput> } }
  >(MUTATION_NOTE);

  const [category, setCategory] = useState(
    data?.Category ? data.Category.SYS_KAT_ID : ""
  );
  const [header, setHeader] = useState(data ? data.Overskrift : "");
  const [note, setNote] = useState(data ? data.Note : "");

  const handleChangeCat = (event: SelectChangeEvent) => {
    setCategory(event.target.value as string);
  };

  const handleChangeHeader = (event: React.ChangeEvent<HTMLInputElement>) => {
    setHeader(event.target.value);
  };

  const handleChangeNote = (event: React.ChangeEvent<HTMLInputElement>) => {
    setNote(event.target.value);
  };

  const handleSubmit = () => {
    if (data && data.SYS_N_ID) {
      handleSubmitEdit();
    } else {
      handleSubmitAdd();
    }
  };

  const handleSubmitAdd = () => {
    if (actorId) {
      updateData({
        variables: {
          actor: {
            SYS_A_ID: actorId,
            Notes: {
              Note: note,
              Header: header,
              Category: category,
            },
          },
        },
      }).then((res) => {
        if (res.data?.Actor?.Notes) {
          const notes = res.data?.Actor?.Notes;
          // since it returns all notes.
          dispatch({
            type: "reload",
            data: notes,
          });
          // hide form.
          closeForm();
          // show msg.
          showMsg("Tilføjet");
        }
      });
    }
  };

  const handleSubmitEdit = () => {
    if (actorId && data) {
      updateData({
        variables: {
          actor: {
            SYS_A_ID: actorId,
            Notes: {
              ID: data.SYS_N_ID,
              Note: note,
              Header: header,
              Category: category,
            },
          },
        },
      }).then((res) => {
        if (res.data?.Actor?.Notes) {
          // since it returns all notes.
          const notes = res.data?.Actor?.Notes;

          // filter updated note
          if (notes) {
            const note = notes.find((note) => note.SYS_N_ID === data.SYS_N_ID);

            // dispatch the updated note only
            if (note) {
              dispatch(updateNote(note));
            }
          }
          // hide form.
          closeForm();
          // show msg.
          showMsg("Opdateret");
        }
      });
    }
  };

  return (
    <>
      <Grid container spacing={2.5}>
        <Grid item xs={12} md={3}>
          <FormControl fullWidth required>
            <Select
              value={category}
              onChange={handleChangeCat}
              variant="outlined"
              size="medium"
            >
              {noteCategories.map((cat) => (
                <MenuItem key={cat.SYS_KAT_ID} value={cat.SYS_KAT_ID}>
                  {cat.DK_Name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={12} md={9}>
          <TextField
            fullWidth
            variant="outlined"
            placeholder="Overskrift"
            value={header}
            onChange={handleChangeHeader}
          />
          <Box mb={3} />
          <TextField
            fullWidth
            variant="outlined"
            multiline
            rows={4}
            placeholder="Note"
            value={note}
            onChange={handleChangeNote}
          />
        </Grid>
      </Grid>
      <Stack
        justifyContent="space-between"
        direction="row"
        sx={{
          pt: 2,
        }}
      >
        <Button variant="default" onClick={closeForm}>
          ANNULLER
        </Button>
        <Button
          variant="contained"
          onClick={handleSubmit}
          disabled={note === "" || header === "" || category === "" || loading}
        >
          GEM
        </Button>
      </Stack>
    </>
  );
};

const NoteDelete = ({
  data,
  closeForm,
}: {
  data?: ActorNote;
  closeForm: () => void;
}) => {
  const { dispatch, showMsg } = useNotes();
  const { actorId } = useEdit();

  // gql
  const [deleteData, { loading }] = useMutation<
    { Actor: { Notes: ActorNote[] } },
    { actor: { SYS_A_ID: string; Notes: Pick<NoteInput, "ID" | "Delete"> } }
  >(MUTATION_NOTE);

  const handleDelete = () => {
    if (actorId && data && data.SYS_N_ID) {
      deleteData({
        variables: {
          actor: {
            SYS_A_ID: actorId,
            Notes: {
              ID: data?.SYS_N_ID,
              Delete: true,
            },
          },
        },
      }).then((res) => {
        if (res) {
          // delete from table
          dispatch(deleteNote(data.SYS_N_ID));
          // show msg
          showMsg("Slettet");
        }
      });
    }
  };

  return (
    <div>
      Slet? <br />
      <Button
        variant="text"
        size="small"
        sx={{ px: 1, minWidth: 0 }}
        onClick={closeForm}
        disabled={loading}
      >
        Nej
      </Button>{" "}
      <Button
        variant="text"
        size="small"
        color="error"
        sx={{ px: 1, minWidth: 0 }}
        disabled={loading}
        onClick={handleDelete}
      >
        Ja
      </Button>
    </div>
  );
};

const ItemMenu: React.FC<{ openEdit: () => void; openDelete: () => void }> = ({
  openEdit,
  openDelete,
}) => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleEdit = () => {
    openEdit();
    handleClose();
  };

  const handleDelete = () => {
    openDelete();
    handleClose();
  };
  return (
    <>
      <IconButton onClick={handleClick}>
        <MoreVert />
      </IconButton>
      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        PaperProps={{
          sx: {
            minWidth: 180,
          },
        }}
      >
        <MenuItem onClick={handleEdit}>Rediger</MenuItem>
        <Divider sx={{ my: 0 }} />
        <MenuItem onClick={handleDelete}>Slet</MenuItem>
      </Menu>
    </>
  );
};

export default Remark;
