import {
  Box,
  Typography,
  ToggleButtonGroup,
  ToggleButton,
} from "@mui/material";
import React, { memo, useState } from "react";

const FieldAutomatchSwitch = ({
  selected,
  onChange,
}: {
  selected: number | null;
  onChange: (checked: number | null) => void;
}) => {
  const [checked, setChecked] = useState<number>(
    selected === null ? -1 : selected
  );
  const switchHandler = (
    event: React.MouseEvent<HTMLElement>,
    newValue: number
  ) => {
    setChecked(newValue);
    onChange(newValue === -1 ? null : newValue);
  };

  return (
    <Box mb={4}>
      <Typography variant="sectionTitle" mb={2.5}>
        Automatches
      </Typography>
      <Box>
        <ToggleButtonGroup
          value={checked}
          exclusive
          onChange={switchHandler}
          color="primary"
        >
          <ToggleButton value={-1} aria-label="Alle">
            Alle
          </ToggleButton>
          <ToggleButton value={0} aria-label="Taendt">
            Taendt
          </ToggleButton>
          <ToggleButton value={1} aria-label="Slukket">
            Slukket
          </ToggleButton>
        </ToggleButtonGroup>
      </Box>
    </Box>
  );
};

export default memo(FieldAutomatchSwitch);
