import {
  Box,
  Card,
  CardContent,
  Collapse,
  Divider,
  Typography,
} from "@mui/material";
import { useActors } from "../../context/actors";
import { EditUserProvider } from "../../context/edit-user";
import { useLayout } from "../../context/layout";
import { useSearchActors } from "../../context/search-actors";
import SearchForm from "./form/SearchForm";
import ActorTable from "./table/ActorTable";
import Pagination from "./table/Pagination";

/**
 * main component of search actors.
 */
const SearchActors = () => {
  return (
    <>
      {/* Form */}
      <CollapsableForm />
      <Line />
      <InitializedTable />
    </>
  );
};

const InitializedTable = () => {
  const { init } = useSearchActors();

  return (
    <>
      {init && (
        <>
          {/* table header / title */}
          <TableHeader />

          {/* table */}
          <Card>
            <EditUserProvider>
              <ActorTable />
            </EditUserProvider>
            <CardContent sx={{ pt: 0 }}>
              <Pagination />
            </CardContent>
          </Card>
        </>
      )}
    </>
  );
};

const TableHeader = () => {
  const { total } = useActors();

  return (
    <Box mb={4}>
      <Typography variant="h5">
        Resultat af søgning: {total} aktøre fundet
      </Typography>
    </Box>
  );
};

const Line = () => {
  const { showSearchForm } = useLayout();
  const { init } = useSearchActors();

  return (
    <>
      {showSearchForm && init && (
        <Divider sx={{ my: 5, borderColor: "#D8D8D8" }} />
      )}
    </>
  );
};

const CollapsableForm = () => {
  const { showSearchForm } = useLayout();

  return (
    <Collapse in={showSearchForm}>
      <SearchForm />
    </Collapse>
  );
};

export default SearchActors;
