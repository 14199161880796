import { MoreVert } from "@mui/icons-material";
import { Button, Divider, Menu as MuiMenu, MenuItem } from "@mui/material";
import React, { useState } from "react";
import { useGetActivities, useUpdateActivity } from "./hooks";

const Menu = () => {
  const { seenAll, archiveAll, undo } = useUpdateActivity();
  const { fetchData, loading } = useGetActivities();

  const [disabled, setDisabled] = useState(false);

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleSeenAll = async () => {
    handleClose();
    setDisabled(true);
    await seenAll();
    setDisabled(false);
  };

  const handleUnseenAll = async () => {
    handleClose();
    setDisabled(true);
    await seenAll(false);
    setDisabled(false);
  };

  const handleArchiveAll = async () => {
    handleClose();
    setDisabled(true);
    await archiveAll();
    setDisabled(false);
  };

  const handleUndo = async () => {
    handleClose();
    setDisabled(true);
    await undo();
    fetchData();
    setDisabled(false);
  };

  return (
    <>
      <Button
        disabled={disabled || loading}
        onClick={handleClick}
        variant="default"
        sx={{
          px: 1,
          minWidth: 44,
          color: "#888888",
        }}
      >
        <MoreVert />
      </Button>
      <MuiMenu
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      >
        {/* seenAllevents(true) */}
        <MenuItem onClick={handleSeenAll}>Marker alle som SET</MenuItem>
        <Divider />
        {/* seenAllEvents(false) */}
        <MenuItem onClick={handleUnseenAll}>Marker alle som USET</MenuItem>
        <Divider />
        {/* archiveAllEvents(true) */}
        <MenuItem onClick={handleArchiveAll}>Arkiver alle</MenuItem>
        <Divider />
        {/* undo */}
        <MenuItem onClick={handleUndo}>Fortryd seneste arkivering</MenuItem>
      </MuiMenu>
    </>
  );
};

export default Menu;
