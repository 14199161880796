import { Box, Popover, Typography } from "@mui/material";
import React, { memo, useEffect } from "react";
import { useData } from "../../../context/data";
import { Answer } from "../../../types";

const Preferences = ({ answers }: { answers: Answer[] }) => {
  const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null);
  const { questions } = useData();
  const [list, setList] = React.useState<string[]>([]);

  const handlePopoverOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  useEffect(() => {
    const getPrefix = (id: string) => {
      let prefix = "";
      let question = questions.find((q) => {
        return q.MPPQ_ID === id;
      });
      if (!question) {
        return "";
      }
      if (!question.Prefix) {
        // if prefix is null, get show value from the first option.
        prefix = question.Properties?.Options[0]?.ShowValue;
      } else {
        prefix = question.Prefix;
      }
      return prefix;
    };

    setList(
      // ignore empty string.
      answers.reduce<string[]>((filtered, answer) => {
        const prefix = getPrefix(answer.MPPQ_ID);
        if (prefix && answer.UserFavorite) {
          filtered.push(prefix);
        }
        return filtered;
      }, [])
    );
  }, [answers, questions]);

  const openPopover = Boolean(anchorEl);

  return (
    <>
      {list.slice(0, 3).map((item, i) => (
        <div key={i}>{item}</div>
      ))}
      {list.length > 3 && (
        <>
          +{list.length - 3} (
          <Typography
            component="span"
            fontSize={13}
            onClick={handlePopoverOpen}
            color="#7BB3BD"
            sx={{
              cursor: "pointer",
            }}
          >
            vis alle
          </Typography>
          )
          <Popover
            open={openPopover}
            anchorEl={anchorEl}
            onClose={handlePopoverClose}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "left",
            }}
          >
            <Box sx={{ p: 2, width: 320 }}>
              <Typography fontWeight={500}>Favoritter</Typography>
              <Typography>{list.join(", ")}</Typography>
            </Box>
          </Popover>
        </>
      )}
    </>
  );
};

export default memo(Preferences);
