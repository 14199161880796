import { LoadingButton } from "@mui/lab";
import { Box } from "@mui/material";
import { useEffect } from "react";
import ActivityItem from "./ActivityItem";
import { useActivities } from "./context";
import { useGetActivities } from "./hooks";

const ActivityList = () => {
  const { activities, loading } = useActivities();
  const { fetchData } = useGetActivities();

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      {loading && activities.length === 0 && (
        <>
          {[1, 2, 3].map((i) => (
            <ActivityItem data={null} key={i} />
          ))}
        </>
      )}
      {activities.map((activity) => (
        <ActivityItem key={activity.ABE_ID} data={activity} />
      ))}
      <Box pt={2} textAlign="center">
        <ShowMore />
      </Box>
    </>
  );
};

const ShowMore = () => {
  const { activities } = useActivities();
  const { fetchData, loading } = useGetActivities();

  const handleShowMore = () => {
    fetchData({
      isInsert: true,
      offset: activities.length,
    });
  };

  return (
    <LoadingButton
      onClick={handleShowMore}
      variant="text"
      disabled={loading}
      loading={loading}
    >
      SHOW MORE
    </LoadingButton>
  );
};

export default ActivityList;
