import { Autocomplete, TextField } from "@mui/material";
import { memo, useEffect, useState } from "react";
import { useData } from "../context/data";

type FieldCountryProps = {
  value?: string | null;
  onChange?: (value: string | null) => void;
};

const SelectCity = memo(({ value, onChange }: FieldCountryProps) => {
  const { cities } = useData();

  const [options, setOptions] = useState<string[]>([]);

  useEffect(() => {
    setOptions(cities.map((c) => c.City));
  }, [cities]);

  const handleChange = (_e: any, newValue: string | null) => {
    if (onChange) {
      onChange(newValue);
    }
  };

  return (
    <>
      <Autocomplete
        options={options}
        value={value}
        renderInput={(params) => <TextField {...params} label="Områder" />}
        onChange={handleChange}
      />
    </>
  );
});

export default SelectCity;
