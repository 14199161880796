import { EventNote } from "@mui/icons-material";
import { InputAdornment, Popover, TextField } from "@mui/material";
import { format } from "date-fns";
import React, { memo, useCallback, useEffect, useState } from "react";
import DateRangePicker, {
  DateRangePickerRange,
} from "../../../../components/DateRangePicker";
import { useDateRange } from "./context/date-range";

const DateRangePickerFilter = memo(() => {
  const { range, setRange } = useDateRange();
  const [label, setLabel] = useState<string>("Dato");

  const [anchorEl, setAnchorEl] = useState<HTMLInputElement | null>(null);

  useEffect(() => {
    if (range.startDate && range.endDate) {
      setLabel(
        `${format(range.startDate, "MM/dd/yyyy")} - ${format(
          range.endDate,
          "MM/dd/yyyy"
        )}`
      );
    }
  }, [range]);

  const handleClick = (event: React.MouseEvent<HTMLInputElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleChange = useCallback((value: DateRangePickerRange) => {
    setRange(value);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const open = Boolean(anchorEl);

  return (
    <div>
      <TextField
        onClick={handleClick}
        value={label}
        InputProps={{
          readOnly: true,
          endAdornment: (
            <InputAdornment position="end">
              <EventNote />
            </InputAdornment>
          ),
          sx: {
            fontSize: 12,
            cursor: "pointer",
            ".MuiInputBase-input": {
              cursor: "pointer",
            },
          },
        }}
        sx={{
          width: 210,
          cursor: "pointer",
        }}
      />
      <Popover
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      >
        <DateRangePicker range={range} onChange={handleChange} />
      </Popover>
    </div>
  );
});

export default DateRangePickerFilter;
