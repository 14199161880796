import { Close } from "@mui/icons-material";
import {
  Box,
  CircularProgress,
  Divider,
  IconButton,
  Modal as MuiModal,
  SxProps,
  Tab,
  Tabs,
} from "@mui/material";
import { Theme } from "@mui/material/styles";
import { makeStyles } from "@mui/styles";
import React from "react";
import Logs from "./Logs";
import MasterData from "./MasterData";
import MatchedUnits from "./MatchedUnits";
import Preferences from "./Preferences";
import Profile from "./Profile";
import Remark from "./Remarks";

const sxBox: SxProps<Theme> = {
  position: "absolute",
  left: "50%",
  top: "50%",
  width: "95%",
  maxWidth: "1080px",
  bgcolor: "background.paper",
  transform: "translate(-50%, -50%)",
  borderRadius: 2,
  border: "1px solid #F4F5F5",
  borderColor: "divider",
  outline: "none",
};

const useTabStyles = makeStyles((theme: Theme) => ({
  default: {
    fontWeight: 400,
    borderRadius: 8,
    minHeight: 44,
    textTransform: "none",
    "&:hover": {
      backgroundColor: theme.palette.grey[50],
    },
  },
  selected: {
    fontWeight: 600,
    backgroundColor: theme.palette.grey[100],
  },
}));

const TabPanel: React.FC<{ index: number; value: number }> = ({
  children,
  value,
  index,
}) => {
  return (
    <div hidden={value !== index}>
      {value === index && <Box>{children}</Box>}
    </div>
  );
};

const Modal = ({
  open,
  handleClose,
  inProgress,
}: {
  open: boolean;
  handleClose: () => void;
  inProgress: boolean;
}) => {
  const tabClasses = useTabStyles();

  const [activeTab, setActiveTab] = React.useState(0);

  const handleChange = (event: React.SyntheticEvent, value: number) => {
    setActiveTab(value);
  };

  return (
    <MuiModal open={open}>
      <Box sx={sxBox}>
        <Box sx={{ position: "relative" }}>
          <Box sx={{ position: "relative", px: 2, py: 1.5 }}>
            <Tabs
              value={activeTab}
              onChange={handleChange}
              TabIndicatorProps={{
                style: { display: "none" },
              }}
            >
              <Tab
                label="Stamdata"
                className={tabClasses.default}
                classes={{ selected: tabClasses.selected }}
              />
              <Tab
                label="Profil"
                className={tabClasses.default}
                classes={{ selected: tabClasses.selected }}
              />
              <Tab
                label="Preferences"
                className={tabClasses.default}
                classes={{ selected: tabClasses.selected }}
              />
              <Tab
                label="Match"
                className={tabClasses.default}
                classes={{ selected: tabClasses.selected }}
              />
              <Tab
                label="Log"
                className={tabClasses.default}
                classes={{ selected: tabClasses.selected }}
              />
              <Tab
                label="Bemærkninger"
                className={tabClasses.default}
                classes={{ selected: tabClasses.selected }}
              />
            </Tabs>
            {inProgress && (
              <Box
                sx={{
                  position: "absolute",
                  right: 56,
                  top: 22,
                }}
              >
                <CircularProgress size={20} />
              </Box>
            )}
            <Box
              sx={{
                position: "absolute",
                right: 16,
                top: 16,
              }}
            >
              <IconButton size="small" onClick={handleClose}>
                <Close />
              </IconButton>
            </Box>
          </Box>
          <Divider />

          {/* modal content */}
          <Box sx={{ height: 500, overflowY: "auto" }}>
            <TabPanel value={activeTab} index={0}>
              <MasterData />
            </TabPanel>
            <TabPanel value={activeTab} index={1}>
              <Profile />
            </TabPanel>
            <TabPanel value={activeTab} index={2}>
              <Preferences />
            </TabPanel>
            <TabPanel value={activeTab} index={3}>
              <MatchedUnits />
            </TabPanel>
            <TabPanel value={activeTab} index={4}>
              <Logs />
            </TabPanel>
            <TabPanel value={activeTab} index={5}>
              <Remark />
            </TabPanel>
          </Box>
        </Box>
      </Box>
    </MuiModal>
  );
};

export default Modal;
