import { Button, Theme } from "@mui/material";
import { makeStyles } from "@mui/styles";
import classnames from "classnames";
import React from "react";
import { NavLink as NavLinkBase, NavLinkProps } from "react-router-dom";

const useStyles = makeStyles((theme: Theme) => ({
  default: {
    textTransform: "none",
    marginRight: theme.spacing(2),
    fontWeight: 400,
  },
  active: {
    fontWeight: 500,
  },
  inactive: {
    borderColor: "transparent",
    backgroundColor: "transparent",
  },
}));

const NavLink = React.forwardRef<HTMLAnchorElement, NavLinkProps>(
  (props, ref) => {
    const classes = useStyles();
    return (
      <NavLinkBase
        ref={ref}
        {...props}
        className={({ isActive }) => {
          return classnames(
            typeof props.className === "string" && props.className,
            classes.default,
            isActive ? classes.active : classes.inactive
          );
        }}
      />
    );
  }
);

const Tabs = () => {
  return (
    <div>
      <Button variant="default" to="." end component={NavLink}>
        Overblik
      </Button>
      <Button variant="default" to="find" component={NavLink}>
        Find aktører
      </Button>
    </div>
  );
};

export default Tabs;
