import { gql, useLazyQuery } from "@apollo/client";
import {
  Box,
  Chip,
  debounce,
  Popover,
  Skeleton,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import React, { memo, useEffect, useRef, useState } from "react";
import Moment from "react-moment";
import TablePagination from "../../../../components/TablePagination";
import { StyledTable } from "../../../../theme/styled-components";
import { useData } from "../../context/data";
import { useEdit } from "../../context/edit";
import { LogMatchedUnits } from "./LogMatchedUnits";

const QUERY_LOGS = gql`
  query Log($id: ID) {
    actor(ID: $id) {
      SYS_A_ID
      Log {
        MPL_ID
        Text
        Unit_ID
        Action
        Timestamp
        Data {
          ActionBy {
            Type
            UserId
          }
          Match {
            AutomatchedPercentage
          }
        }
      }
    }
  }
`;

type Log = {
  MPL_ID: string;
  Text: string;
  Unit_ID: string | null;
  Action: string;
  Timestamp: string;
  Data?: {
    ActionBy?: {
      Type: string;
      UserId: string | null;
    };
    Match?: {
      AutomatchedPercentage?: number;
    };
  };
  unitIds?: string[];
};

const Logs = () => {
  const { actorId } = useEdit();
  const [loading, setLoading] = useState(true);
  const [logs, setLogs] = useState<Log[]>([]);
  const [logList, setLogList] = useState<Log[]>([]);

  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(10);

  const [getLogs] = useLazyQuery<{ actor: { Log: Log[] } }, { id: string }>(
    QUERY_LOGS
  );

  const debounceQuery = useRef(
    debounce((actorId: string) => {
      setLoading(true);
      getLogs({
        variables: {
          id: actorId,
        },
      })
        .then((res) => {
          if (res?.data?.actor?.Log) {
            setLogs(res?.data?.actor?.Log);
          }
        })
        .finally(() => {
          setLoading(false);
        });
    }, 1000)
  );

  useEffect(() => {
    if (actorId) {
      // fetch logs.
      debounceQuery.current(actorId);
    }
  }, [actorId]);

  useEffect(() => {
    // concat 'unit_matched' action.
    let items: Log[] = [];
    let unitMatchedLogIndex: number = 0;
    let unitMatchedLog: Log | null = null;
    let unitMatchedLogIds: string[] = [];

    logs.forEach((log, i) => {
      if (log.Action === "unit_matched") {
        if (log.Unit_ID) {
          unitMatchedLogIds.push(log.Unit_ID);
        }
        if (unitMatchedLog) {
          items[unitMatchedLogIndex] = {
            ...unitMatchedLog,
            unitIds: unitMatchedLogIds,
          };
        } else {
          unitMatchedLog = { ...log, unitIds: unitMatchedLogIds };
          unitMatchedLogIndex = i;
          items.push(unitMatchedLog);
        }
      } else {
        unitMatchedLog = null;
        unitMatchedLogIds = [];
        items.push(log);
      }
    });

    setLogList(items);
  }, [logs]);

  return (
    <Box p={2.5}>
      <Typography px={2} mb={1.5} variant="sectionTitle">
        Log
      </Typography>
      <TableContainer>
        <StyledTable>
          <TableHead>
            <TableRow>
              <TableCell width={180}>Dato</TableCell>
              <TableCell>Aktivitet</TableCell>
              <TableCell width={200} align="center">
                Handling af
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {loading ? (
              <SkeletonRows />
            ) : (
              <>
                {logList
                  .slice((page - 1) * perPage, page * perPage)
                  .map((log) => (
                    <LogRow key={log.MPL_ID} log={log} />
                  ))}
              </>
            )}
          </TableBody>
        </StyledTable>
      </TableContainer>
      {/* static pagination */}
      <TablePagination
        total={logList.length}
        page={page}
        perPage={perPage}
        onPageChange={setPage}
        onPerPageChange={setPerPage}
      />
    </Box>
  );
};

const LogRow = memo(({ log }: { log: Log }) => {
  return (
    <TableRow>
      <TableCell>
        <Moment format="MMM D, YYYY - HH:mm">{log.Timestamp}</Moment>
      </TableCell>
      <TableCell>
        {log.Action === "unit_matched" ? (
          <LogMatchedUnits
            unitIds={log.unitIds ?? []}
            percentage={Number(log.Data?.Match?.AutomatchedPercentage)}
          />
        ) : (
          log.Action
        )}
      </TableCell>
      <TableCell align="center">
        <LogActionChip log={log} />
      </TableCell>
    </TableRow>
  );
});

const LogActionChip = memo(({ log }: { log: Log }) => {
  const { users } = useData();
  const [name, setName] = useState("");
  const [company, setCompany] = useState<string | undefined>("");

  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const openPopover = Boolean(anchorEl);

  useEffect(() => {
    if (log.Data?.ActionBy?.UserId && users) {
      const user = users.find((u) => u.U_ID === log.Data?.ActionBy?.UserId);
      if (user) {
        setName(user.Navn);
        setCompany(user.Company?.Name);
      }
    }
  }, [log, users]);

  const handlePopoverOpen = (event: React.MouseEvent<HTMLElement>) => {
    if (name) {
      setAnchorEl(event.currentTarget);
    }
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <Chip
        label={log.Data?.ActionBy?.Type}
        size="small"
        sx={{
          backgroundColor: "rgba(227, 182, 42, 0.2)",
          "&:hover": {
            backgroundColor: "rgba(227, 182, 42, 0.2)",
          },
        }}
        onClick={name ? handlePopoverOpen : undefined}
      />
      <Popover
        open={openPopover}
        anchorEl={anchorEl}
        onClose={handlePopoverClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      >
        <Box sx={{ p: 2, width: 200 }}>
          <Typography fontWeight={500}>{name}</Typography>
          <Typography>{company}</Typography>
        </Box>
      </Popover>
    </>
  );
});

const SkeletonRows = () => {
  return (
    <>
      {[0, 1, 3].map((item) => (
        <TableRow key={item}>
          <TableCell>
            <Skeleton variant="text" />
          </TableCell>
          <TableCell>
            <Skeleton variant="text" />
          </TableCell>
          <TableCell align="center">
            <Skeleton width={72} height={24} sx={{ display: "inline-block" }} />
          </TableCell>
        </TableRow>
      ))}
    </>
  );
};

export default Logs;
