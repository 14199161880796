import { gql, useLazyQuery } from "@apollo/client";
import Cookies from "js-cookie";
import React, { createContext, useEffect, useReducer } from "react";

// === gql
const AUTH = gql`
  query Auth($token: String) {
    token(token: $token) {
      valid
      user {
        U_ID
        Titel
        Navn
      }
    }
  }
`;

// === types
type AuthUser = {
  U_ID: string;
  Titel: string;
  Navn: string;
};

type AuthQueryResult = {
  token?: {
    valid: boolean;
    user: AuthUser | null;
  };
};

// reducer and context.
type ReducerAction =
  | { type: "authenticate"; user: AuthUser }
  | { type: "signout" };

type AuthState = {
  isAuthenticated: boolean;
  user: AuthUser | null;
};

type AuthContextType = {
  isAuthenticated: boolean;
  user: AuthUser | null;
};

// === reducer
const initialState: AuthState = {
  isAuthenticated: false,
  user: null,
};

const AuthReducer = (state: AuthState, action: ReducerAction): AuthState => {
  switch (action.type) {
    case "authenticate":
      return {
        isAuthenticated: true,
        user: action.user,
      };
    case "signout":
      return {
        isAuthenticated: false,
        user: null,
      };
    default:
      return state;
  }
};

// === create context
const AuthContext = createContext<AuthContextType | null>(null);

// === context provider
export const AuthProvider: React.FC = ({ children }) => {
  const [state, dispatch] = useReducer(AuthReducer, initialState);

  const [getAuth] = useLazyQuery<AuthQueryResult>(AUTH);

  const checkAuth = () => {
    const token = getToken();

    getAuth({
      variables: {
        token,
      },
    })
      .then((res) => {
        if (res.data?.token?.valid && res.data?.token?.user) {
          dispatch({
            type: "authenticate",
            user: res.data.token.user,
          });
          return;
        }
      })
      .catch(() => {});
  };

  useEffect(() => {
    checkAuth();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <AuthContext.Provider value={state}>
      {state.isAuthenticated && children}
    </AuthContext.Provider>
  );
};

// === hook

// === functions
// get token from cookie
export const getToken = (): string => {
  const cookieEstatetool = Cookies.get("estatetool");
  if (cookieEstatetool) {
    const [, token] = cookieEstatetool.split("token=");
    return token;
  }
  return "";
};
