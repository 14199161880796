/**
 * All static data.
 */
import { gql, useLazyQuery } from "@apollo/client";
import React, { createContext, useContext, useEffect, useState } from "react";

// === gql
const QUERY_SYSTEM = gql`
  query System {
    system {
      system_domain
    }
  }
`;

const QUERY_USERS = gql`
  query Users {
    users {
      U_ID
      Titel
      Navn
      Mobil
      Company {
        Name
      }
    }
  }
`;

const QUERY_QUESTION = gql`
  query Questions {
    questions {
      MPPQ_ID
      Prefix
      group {
        Name
        MPG_ID
      }
      Properties {
        Options {
          ShowValue
          Value
          Selected
          DefaultSelected
        }
      }
    }
  }
`;

const QUERY_PREFERENCES = gql`
  query Data {
    popularPreferences {
      MPPQ_ID
      popularcount
      prefix
    }
  }
`;

const QUERY_NOTE_CATEGORIES = gql`
  query NoteCategories {
    NoteCategories {
      SYS_KAT_ID
      DK_Name
    }
  }
`;

const QUERY_ACTORS_FILTERS = gql`
  query Actorsfilters {
    actorsfilters {
      NumberOfMatchesFilter {
        min
        max
      }
    }
  }
`;

const QUERY_ACTOR_OPTIONS = gql`
  query ActorOptions {
    actorOptions {
      economy {
        Name
        ID
      }
      cities {
        City
      }
      countries {
        ISO_3166_DA
      }
    }
  }
`;

const QUERY_ACTIVITY_BOARD = gql`
  query Activityboard {
    actorOptions {
      activityboard {
        eventStatuses {
          Name
          ID
        }
      }
    }
  }
`;

// === types
type System = {
  system_domain: string;
};

type User = {
  U_ID: string;
  Titel: string;
  Navn: string;
  Mobil: string;
  Company?: {
    Name: string;
  };
};

type QuestionOption = {
  ShowValue: string;
  Value: string;
  Selected: false;
  DefaultSelected: false;
};

export type Question = {
  MPPQ_ID: string;
  Prefix: string;
  group: {
    Name: string;
    MPG_ID: number;
  };
  Properties: {
    Options: QuestionOption[];
  };
};

type QuestionGroup = {
  housing: Question[];
  economy: Question[];
  facilities: Question[];
};

type Preference = {
  MPPQ_ID: string;
  prefix: string;
};

type NoteCategory = {
  SYS_KAT_ID: string;
  DK_Name: string;
};

type ActorsFilters = {
  NumberOfMatchesFilter: {
    min: number;
    max: number;
  };
};

type Country = {
  ISO_3166_DA: string;
};

type City = {
  City: string;
};

type Economy = {
  Name: string;
  ID: string;
};

type ActorOptions = {
  countries: Country[];
  cities: City[];
  economy: Economy[];
};

type EventStatus = {
  ID: string;
  Name: string;
};

type ContextType = {
  users: User[];
  questions: Question[];
  questionGroup: QuestionGroup | null;
  preferences: Preference[];
  noteCategories: NoteCategory[];
  dataFilters?: ActorsFilters;
  countries: Country[];
  cities: City[];
  economy: Economy[];
  eventStatus: EventStatus[];
  systemDomain: string;
};

// === create context
const initialData: ContextType = {
  users: [],
  questions: [],
  preferences: [],
  noteCategories: [],
  questionGroup: null,
  countries: [],
  cities: [],
  economy: [],
  eventStatus: [],
  systemDomain: "",
};
const DataContext = createContext<ContextType>(initialData);

// === context provider
const DataProvider: React.FC = ({ children }) => {
  const [getUsers] = useLazyQuery<{
    users?: User[];
  }>(QUERY_USERS);

  const [getQuestions] = useLazyQuery<{
    questions?: Question[];
  }>(QUERY_QUESTION);

  const [getPreferences] = useLazyQuery<{
    popularPreferences?: Preference[];
  }>(QUERY_PREFERENCES);

  const [getNoteCategories] = useLazyQuery<{
    NoteCategories: NoteCategory[];
  }>(QUERY_NOTE_CATEGORIES);

  const [getActorsFilters] =
    useLazyQuery<{ actorsfilters: ActorsFilters }>(QUERY_ACTORS_FILTERS);

  const [getActorOptions] =
    useLazyQuery<{ actorOptions: ActorOptions }>(QUERY_ACTOR_OPTIONS);

  const [getActivityBoard] = useLazyQuery<{
    actorOptions?: { activityboard?: { eventStatuses?: EventStatus[] } };
  }>(QUERY_ACTIVITY_BOARD);

  const [getSystem] = useLazyQuery<{
    system?: System;
  }>(QUERY_SYSTEM);

  const [value, setValue] = useState<ContextType>(initialData);

  useEffect(() => {
    // concat promise.
    Promise.allSettled([
      getUsers(),
      getQuestions(),
      getPreferences(),
      getNoteCategories(),
      getActorsFilters(),
      getActorOptions(),
      getActivityBoard(),
      getSystem(),
    ]).then((values) => {
      let users: User[] = [];
      let questions: Question[] = [];
      let preferences: Preference[] = [];
      let noteCategories: NoteCategory[] = [];
      let countries: Country[] = [];
      let cities: City[] = [];
      let economy: Economy[] = [];
      let eventStatus: EventStatus[] = [];
      let dataFilters;
      let systemDomain = "";

      if (values[0].status === "fulfilled") {
        users = values[0].value.data?.users ?? [];
      }

      if (values[1].status === "fulfilled") {
        questions = values[1].value.data?.questions ?? [];
      }

      if (values[2].status === "fulfilled") {
        preferences = values[2].value.data?.popularPreferences ?? [];
      }

      if (values[3].status === "fulfilled") {
        noteCategories = values[3].value.data?.NoteCategories ?? [];
      }

      if (values[4].status === "fulfilled") {
        dataFilters = values[4].value.data?.actorsfilters;
      }

      if (
        values[5].status === "fulfilled" &&
        values[5].value.data?.actorOptions?.countries
      ) {
        countries = values[5].value.data?.actorOptions.countries;
        cities = values[5].value.data?.actorOptions.cities;
        economy = values[5].value.data?.actorOptions.economy;
      }

      if (values[6].status === "fulfilled") {
        if (values[6].value.data?.actorOptions?.activityboard?.eventStatuses) {
          eventStatus =
            values[6].value.data?.actorOptions?.activityboard?.eventStatuses;
        }
      }

      if (values[7].status === "fulfilled") {
        if (values[7].value.data?.system?.system_domain) {
          systemDomain = values[7].value.data?.system?.system_domain;
        }
      }

      // question group
      const questionGroup = getQuestionGroup(questions);

      setValue({
        users,
        questions,
        questionGroup,
        preferences,
        noteCategories,
        dataFilters,
        countries,
        cities,
        economy,
        eventStatus,
        systemDomain,
      });
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return <DataContext.Provider value={value}>{children}</DataContext.Provider>;
};

// === custom hook
const useData = () => {
  const context = useContext(DataContext);
  if (context === undefined) {
    throw new Error("useData must be used within a CountProvider");
  }
  return context;
};

// === functions.
const getQuestionGroup = (questions: Question[]): QuestionGroup => {
  let housing: Question[] = [];
  let economy: Question[] = [];
  let facilities: Question[] = [];
  questions
    .filter((question) => question.Properties && question.Properties.Options)
    .forEach((question) => {
      switch (question.group.MPG_ID) {
        case 801:
          housing.push(question);
          break;
        case 802:
          facilities.push(question);
          break;
        case 803:
          economy.push(question);
          break;
      }
    });

  return { housing, economy, facilities };
};

export { useData, DataProvider };
