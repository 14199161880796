import { DatePicker, LocalizationProvider } from "@mui/lab";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import { TextField } from "@mui/material";
import { format } from "date-fns";
import React, { memo, useEffect } from "react";

const FieldDate = ({
  value,
  onChange,
}: {
  value: string;
  onChange: (value: string) => void;
}) => {
  const [datevalue, setDatevalue] = React.useState<Date | null>(null);

  useEffect(() => {
    try {
      if (value) {
        setDatevalue(new Date(value));
      }
    } catch (error) {}
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleChange = (date: Date | null) => {
    setDatevalue(date);
    try {
      if (date && typeof date === "object") {
        onChange(format(date, "yyyy-MM-dd"));
      } else {
        onChange("");
      }
    } catch (error) {
      onChange("");
    }
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <DatePicker
        label="Dato oprettet"
        value={datevalue}
        onChange={handleChange}
        renderInput={(params) => (
          <TextField
            {...params}
            sx={{
              backgroundColor: "#FFFFFF",
            }}
          />
        )}
      />
    </LocalizationProvider>
  );
};

export default memo(FieldDate);
