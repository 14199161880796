import { Box } from "@mui/material";
import {
  addDays,
  addMonths,
  addYears,
  endOfDay,
  endOfMonth,
  endOfWeek,
  endOfYear,
  isSameDay,
  startOfDay,
  startOfMonth,
  startOfWeek,
  startOfYear,
} from "date-fns";
import { da } from "date-fns/locale";
import { useEffect, useState } from "react";
import {
  createStaticRanges,
  DateRange,
  DefinedRange,
  Preview,
  Range,
  RangeKeyDict,
  StaticRange,
} from "react-date-range";
import "react-date-range/dist/styles.css"; // main css file
import "react-date-range/dist/theme/default.css"; // theme css file
import "./date-range-picker.css";

const defineds = {
  startOfToday: startOfDay(new Date()),
  endOfToday: endOfDay(new Date()),
  startOfLastMonth: startOfMonth(addMonths(new Date(), -1)),
  endOfLastMonth: endOfMonth(addMonths(new Date(), -1)),
  startOfLast3Month: startOfMonth(addMonths(new Date(), -3)),
  startOfLastYear: startOfYear(addYears(new Date(), -1)),
  endOfLastYear: endOfYear(addYears(new Date(), -1)),

  startOfWeek: startOfWeek(new Date()),
  endOfWeek: endOfWeek(new Date()),
  startOfLastWeek: startOfWeek(addDays(new Date(), -7)),
  endOfLastWeek: endOfWeek(addDays(new Date(), -7)),
  startOfYesterday: startOfDay(addDays(new Date(), -1)),
  endOfYesterday: endOfDay(addDays(new Date(), -1)),
  startOfMonth: startOfMonth(new Date()),
  endOfMonth: endOfMonth(new Date()),
};

const rangeHandler = ({
  label,
  range,
  customRender,
}: {
  label: string;
  range: Preview;
  customRender?: boolean;
}): StaticRange => {
  return {
    label: label,
    range: () => ({
      startDate: range.startDate,
      endDate: range.endDate,
      color: "#496A70",
    }),
    hasCustomRendering: customRender,
    isSelected: (selectedRange) => {
      if (
        selectedRange.startDate &&
        selectedRange.endDate &&
        range.startDate &&
        range.endDate
      ) {
        return (
          isSameDay(selectedRange.startDate, range.startDate) &&
          isSameDay(selectedRange.endDate, range.endDate)
        );
      }
      return false;
    },
  };
};

const staticRanges = createStaticRanges([
  // today
  rangeHandler({
    label: "I dag",
    range: {
      startDate: defineds.startOfToday,
      endDate: defineds.endOfToday,
    },
  }),
  // Seneste 7 dage
  // Last 7 days
  rangeHandler({
    label: "Seneste 7 dage",
    range: {
      startDate: addDays(new Date(), -6),
      endDate: defineds.startOfToday,
    },
  }),
  // Seneste 30 dage
  // Last 30 days
  rangeHandler({
    label: "Seneste 30 dage",
    range: {
      startDate: addDays(new Date(), -29),
      endDate: defineds.startOfToday,
    },
  }),
  // Seneste måned
  // Last month
  rangeHandler({
    label: "Seneste måned",
    range: {
      startDate: defineds.startOfLastMonth,
      endDate: defineds.endOfLastMonth,
    },
  }),
  // Seneste 3 måneder
  // Last 3 months
  rangeHandler({
    label: "Seneste 3 måneder",
    range: {
      startDate: defineds.startOfLast3Month,
      endDate: defineds.endOfLastMonth,
    },
  }),
  // Seneste år
  // Last years
  rangeHandler({
    label: "Seneste år",
    range: {
      startDate: defineds.startOfLastYear,
      endDate: defineds.endOfLastYear,
    },
  }),
  // Alle
  // Always
  rangeHandler({
    label: "Alle",
    range: {},
  }),
]);

export type DateRangePickerRange = Pick<Preview, "startDate" | "endDate">;

const DateRangePicker = ({
  range,
  onChange,
}: {
  range: DateRangePickerRange;
  onChange: (range: DateRangePickerRange) => void;
}) => {
  const [state, setState] = useState<Range[]>([
    {
      // startDate: addDays(new Date(), -6),
      // endDate: defineds.startOfToday,
      startDate: range.startDate,
      endDate: range.endDate,
      key: "selection",
      showDateDisplay: false,
      color: "#496A70",
    },
  ]);

  useEffect(() => {
    setState([
      {
        // startDate: addDays(new Date(), -6),
        // endDate: defineds.startOfToday,
        startDate: range.startDate,
        endDate: range.endDate,
        key: "selection",
        showDateDisplay: false,
        color: "#496A70",
      },
    ]);
  }, [range]);

  const handleChange = (item: RangeKeyDict) => {
    onChange({
      startDate: item.selection.startDate,
      endDate: item.selection.endDate,
    });
  };

  return (
    <Box
      sx={{
        display: "flex",
      }}
    >
      <DefinedRange
        onChange={handleChange}
        ranges={state}
        inputRanges={[]}
        headerContent={
          <Box sx={{ fontWeight: 600, pb: 1, pt: 2, textAlign: "center" }}>
            Genveje
          </Box>
        }
        staticRanges={staticRanges}
      />
      <DateRange
        onChange={handleChange}
        moveRangeOnFirstSelection={false}
        preventSnapRefocus={true}
        months={1}
        ranges={state}
        direction="horizontal"
        locale={da}
        color="#496A70"
        rangeColors={["#496A70"]}
      />
    </Box>
  );
};

export default DateRangePicker;
