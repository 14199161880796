import React, { createContext, useContext, useReducer } from "react";
import { Actor } from "../types";

// === types
export type VariablesType = {
  limit: number;
  offset: number;
  mppqId?: string[];
  answeredValueIds?: string[];
  answeredValueValues?: boolean[];
  answeredValuePreferences?: boolean[];
  search: string;
  sellers?: string[];
  status?: string;
  //automatch: boolean;
  numberOfMatches: number;
  numberOfUnseenMatches: number;
  date?: string;
  automatchSwitch: number | null;
  matchSwitch: number | null;
};

type ReducerType = {
  actors: Actor[];
  total: number;
};

type ReducerAction =
  | { type: "reload"; actors: Actor[] }
  | { type: "update"; actor: Pick<Actor, "SYS_A_ID"> & Partial<Actor> }
  | { type: "insert"; actor: Actor };

type ActorsContextType = {
  actors: Actor[];
  total: number;
};

// === reducer
const reducer = (state: ReducerType, action: ReducerAction): ReducerType => {
  switch (action.type) {
    case "reload":
      // get total from an actor
      const total =
        action.actors &&
        action.actors[0] &&
        action.actors[0].TotalActors &&
        action.actors[0]?.TotalActors > 0
          ? action.actors[0].TotalActors
          : 0;
      return { total: total, actors: action.actors };
    case "update":
      const actors = state.actors.map((actor) => {
        if (actor.SYS_A_ID === action.actor.SYS_A_ID) {
          return { ...actor, ...action.actor };
        }
        return actor;
      });
      return { ...state, actors: actors };
    default:
      throw new Error(`Unhandled action type: ${action.type}`);
  }
};

// === context
const ActorsContext = createContext<ActorsContextType | undefined>(undefined);
const ActorsDispatchContext = createContext<
  | {
      dispatch: React.Dispatch<ReducerAction>;
    }
  | undefined
>(undefined);

const ActorsProvider: React.FC = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, { actors: [], total: 0 });

  return (
    <ActorsContext.Provider
      value={{ actors: state.actors, total: state.total }}
    >
      <ActorsDispatchContext.Provider value={{ dispatch }}>
        {children}
      </ActorsDispatchContext.Provider>
    </ActorsContext.Provider>
  );
};

// === custom hook
const useActors = () => {
  const context = useContext(ActorsContext);
  if (context === undefined) {
    throw new Error("useActors must be used within a ActorsProvider");
  }
  return context;
};

const useActorsDispatch = () => {
  const context = useContext(ActorsDispatchContext);
  if (context === undefined) {
    throw new Error("useActorsDispatch must be used within a ActorsProvider");
  }
  return context;
};

export { ActorsProvider, useActors, useActorsDispatch };
