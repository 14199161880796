import { AccountCircleOutlined, Edit, MoreVert } from "@mui/icons-material";
import {
  Box,
  Button,
  Divider,
  Grid,
  Menu,
  MenuItem,
  Skeleton,
  Stack,
  Typography,
} from "@mui/material";
import React, { memo, useState } from "react";
import Moment from "react-moment";
import { useIframeModal } from "../../../../context/iframe-modal";
import { useData } from "../../context/data";
import { useEdit } from "../../context/edit";
import { useUpdateActivity } from "./hooks";
import { Activity } from "./types";

const ActivityItem = memo(({ data }: { data: Activity | null }) => {
  return (
    <Box
      sx={{
        backgroundColor: "#F9FAFA",
        border: "1px solid #F4F5F5",
        borderRadius: "8px",
        mb: 2.5,
        p: 2.5,
      }}
    >
      <Grid container>
        <Grid item xs={12} md={6}>
          {/* description */}
          <Box sx={{ fontWeight: 500, fontSize: 14, mb: 0.5 }}>
            {data ? data.Description : <Skeleton width={220} />}
          </Box>

          {/* actor */}
          <Typography
            variant="sectionDesc"
            component="div"
            sx={{ fontSize: 12, mb: 0.5 }}
          >
            {data ? (
              <>
                Aktør: {data.Actor?.Firstname} {data.Actor?.Lastname}
              </>
            ) : (
              <Skeleton width={200} />
            )}
          </Typography>

          <div>
            {/* type */}
            <Box sx={{ display: "inline-block", mr: 2 }}>
              {data ? <Type data={data} /> : <Skeleton width={95} />}
            </Box>
            {/* date */}
            <Box sx={{ display: "inline-block", fontSize: 11 }}>
              {data ? (
                <Moment format="MMM D, YYYY - HH:mm">{data.EventTime}</Moment>
              ) : (
                <Skeleton width={120} />
              )}
            </Box>
          </div>
        </Grid>

        <Grid item xs={12} sm={6} md={3}>
          {/* user */}
          {data?.user && (
            <Stack
              alignItems="center"
              direction={{ xs: "row", md: "column" }}
              sx={{ pt: { xs: 2, md: 1 } }}
            >
              <AccountCircleOutlined />
              <Typography mt={0.5} ml={{ xs: 0.5, md: 0 }} fontSize={14}>
                {data.user?.Navn}
              </Typography>
            </Stack>
          )}
        </Grid>

        <Grid item xs={12} sm={6} md={3}>
          <Stack direction="row" justifyContent="flex-end" sx={{ pt: 1 }}>
            {/* seen */}
            {data ? (
              <>
                <Seen data={data} />
                <Box sx={{ ml: 3 }}>
                  <MenuAction data={data} />
                </Box>
              </>
            ) : (
              <>
                <Skeleton width={70} height={45} sx={{ mr: 3 }} />
                <Skeleton width={45} height={45} />
              </>
            )}
          </Stack>
        </Grid>
      </Grid>
    </Box>
  );
});

const Type = ({ data }: { data: Activity }) => {
  let bg = "#888888";
  if (data.activityboard_event_type) {
    switch (data.Type) {
      case "INFORMATION":
        bg = data.activityboard_event_type.ColorInfo;
        break;
      case "ALARM":
        bg = data.activityboard_event_type.ColorAlert;
        break;
      case "VIGTIG":
        bg = data.activityboard_event_type.ColorWarning;
        break;
    }
  }

  const color = getContrast(bg) === "black" ? "#000" : "#fff";

  return (
    <Box
      sx={{
        display: "inline-block",
        minWidth: 95,
        textAlign: "center",
        borderRadius: "5px",
        fontSize: 10,
        px: 1,
        py: 0.25,
        color: color,
        backgroundColor: bg,
      }}
    >
      {data.Type}
    </Box>
  );
};

const Seen = ({ data }: { data: Activity }) => {
  const { seen, archive } = useUpdateActivity();

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const [disabled, setDisabled] = useState(false);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleSeen = () => {
    handleClose();
    setDisabled(true);
    seen(data.ABE_ID).finally(() => {
      setDisabled(false);
    });
  };

  const handleArchive = () => {
    handleClose();
    setDisabled(true);
    archive(data.ABE_ID).finally(() => {
      setDisabled(false);
    });
  };

  return (
    <>
      {!data.Seen && (
        <>
          <Button
            onClick={handleClick}
            variant="default"
            disabled={disabled}
            endIcon={<Edit sx={{ fontSize: "14px !important", ml: 1 }} />}
            sx={{ fontSize: 12, color: "#888888", fontWeight: 600 }}
          >
            USET
          </Button>
          <Menu
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "right",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
          >
            <MenuItem onClick={handleSeen}>Marker som SET</MenuItem>
            <Divider />
            <MenuItem onClick={handleArchive}>Arkiver</MenuItem>
          </Menu>
        </>
      )}
    </>
  );
};

const MenuAction = ({ data }: { data: Activity }) => {
  const { archive } = useUpdateActivity();
  const [disabled, setDisabled] = useState(false);
  const { open: openActor } = useEdit();
  const { systemDomain } = useData();
  const { open: openIframe } = useIframeModal();

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleArchive = () => {
    handleClose();
    setDisabled(true);
    archive(data.ABE_ID).finally(() => {
      setDisabled(false);
    });
  };

  const handleActor = () => {
    handleClose();
    if (data.Actor?.SYS_A_ID) {
      openActor(data.Actor?.SYS_A_ID);
    }
  };

  const handleUnitLink = () => {
    if (systemDomain && data.B_ID) {
      const url = `${systemDomain}/subwindow_estate_data_holder.asp?b_id=${data.B_ID}`;
      openIframe(url);
      // window.open(url, "_blank");
    }
  };

  return (
    <>
      <Button
        onClick={handleClick}
        variant="default"
        sx={{
          px: 1,
          minWidth: 44,
          color: "#888888",
        }}
        disabled={disabled}
      >
        <MoreVert />
      </Button>
      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      >
        {/* Go to unit */}
        {data.B_ID && (
          <MenuItem onClick={handleUnitLink}>Gå til enhed</MenuItem>
        )}
        {data.B_ID && <Divider />}
        {/* Go to actor */}
        {data.Actor?.SYS_A_ID && (
          <MenuItem onClick={handleActor}>Gå til aktør</MenuItem>
        )}
        {data.Actor?.SYS_A_ID && <Divider />}
        {/* Archive */}
        <MenuItem onClick={handleArchive}>Arkiver</MenuItem>
      </Menu>
    </>
  );
};

const getContrast = (hexcolor: string) => {
  hexcolor = hexcolor.replace("#", "");
  let r = parseInt(hexcolor.substring(0, 2), 16);
  let g = parseInt(hexcolor.substring(2, 4), 16);
  let b = parseInt(hexcolor.substring(4, 6), 16);
  let yiq = (r * 299 + g * 587 + b * 114) / 1000;
  return yiq >= 150 ? "black" : "white";
};

export default ActivityItem;
