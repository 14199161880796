import React, { createContext, useCallback, useContext, useState } from "react";
import ModalUser from "../components/edit/ModalUser";
import { Actor } from "../types";

type EditUserContextType = {
  open: (actor: Actor) => void;
};

const EditUserContext = createContext<EditUserContextType>({
  open: () => {},
});

// === context
const EditUserProvider: React.FC = ({ children }) => {
  const [actor, setActor] = useState<Actor | null>(null);
  const [openModal, setOpenModal] = useState(false);

  const open = useCallback((actor: Actor) => {
    setOpenModal(true);
    setActor(actor);
  }, []);

  const handleClose = useCallback(() => {
    setOpenModal(false);
    setActor(null);
  }, []);

  return (
    <EditUserContext.Provider value={{ open }}>
      {children}
      {actor && (
        <ModalUser open={openModal} handleClose={handleClose} actor={actor} />
      )}
    </EditUserContext.Provider>
  );
};

// === custom hook
const useEditUser = () => {
  const context = useContext(EditUserContext);
  if (context === undefined) {
    throw new Error("useEditUser must be used within a EditUserProvider");
  }
  return context;
};

export { useEditUser, EditUserProvider };
