import { gql, useLazyQuery, useQuery } from "@apollo/client";
import { ArrowDownwardRounded, ArrowUpwardRounded } from "@mui/icons-material";
import {
  Box,
  Divider,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  Skeleton,
  Stack,
  Typography,
} from "@mui/material";
import React, { memo, useCallback, useEffect, useState } from "react";
import { Panel, PanelTitle } from "../../../../components/Panel";

const GET_FILTERS = gql`
  query GetKPIFilter {
    filters {
      ABF_ID
      Name
      HoursFromNow
    }
  }
`;

const GET_VALUE = gql`
  query KPIByValue($value: Int) {
    kpiByValueForHoursFromNow(ValueForHoursFromNow: $value) {
      ABKPI_ID
      Name
      Description
      activityboard_kpi_values {
        ABKPI_ID
        ValueForHoursFromNow
        Value
        DeltaValue
        Description
      }
    }
  }
`;

type FilterType = {
  ABF_ID: string;
  Name: string;
  HoursFromNow: number;
};

type KpiActivityBoardType = {
  ABKPI_ID: string;
  ValueForHoursFromNow: number;
  Value: number;
  DeltaValue: number;
  Description: string;
};

type KpiType = {
  ABKPI_ID: string;
  Name: string;
  Description: string;
  activityboard_kpi_values: KpiActivityBoardType[];
};

const Kpi = memo(() => {
  const [hoursFilter, setHoursFilter] = useState<number | null>(null);

  const { data: filterData } = useQuery<{ filters: FilterType[] }>(GET_FILTERS);
  const [getData, { data, loading }] = useLazyQuery<
    { kpiByValueForHoursFromNow: KpiType[] },
    { value: number }
  >(GET_VALUE);

  useEffect(() => {
    if (filterData && filterData.filters) {
      setHoursFilter(filterData.filters[0].HoursFromNow);
    }
  }, [filterData]);

  useEffect(() => {
    if (hoursFilter !== null) {
      getData({
        variables: {
          value: hoursFilter,
        },
      });
    }
  }, [getData, hoursFilter]);

  const handleSelectFilter = useCallback((value: number) => {
    setHoursFilter(value);
  }, []);

  return (
    <Panel
      minimizable={false}
      sx={{ height: "100%" }}
      header={<PanelTitle>KPI</PanelTitle>}
      actions={
        <HourFilter
          filters={filterData?.filters}
          value={hoursFilter}
          onSelect={handleSelectFilter}
        />
      }
    >
      <Grid container columnSpacing={{ lg: 6, md: 4, xs: 3 }}>
        {loading && (
          <>
            {[1, 2, 3, 4, 5, 6, 7, 8, 9].map((i) => (
              <React.Fragment key={i}>
                <Grid item xs={6} sm={4} sx={{ my: { md: 3, xs: 2 } }}>
                  <KpiItemSkeleton />
                </Grid>
                <DividerItem ind={0} />
              </React.Fragment>
            ))}
          </>
        )}
        {!loading &&
          data?.kpiByValueForHoursFromNow.map((data, i) => (
            <React.Fragment key={data.ABKPI_ID}>
              <Grid item xs={6} sm={4} sx={{ my: { md: 3, xs: 2 } }}>
                <KpiItem data={data} />
              </Grid>
              <DividerItem ind={i} />
            </React.Fragment>
          ))}
      </Grid>
    </Panel>
  );
});

const DividerItem = ({ ind }: { ind: number }) => (
  <Divider
    orientation="vertical"
    flexItem
    sx={{
      display: {
        sm: (ind + 1) % 3 === 0 ? "none" : "block",
        xs: (ind + 1) % 2 === 0 ? "none" : "block",
      },
      ml: "-1px",
      alignSelf: "center",
      height: 22,
      borderColor: "#F4F5F5",
      "&:last-child": {
        display: "none",
      },
    }}
  />
);

const HourFilter = memo(
  ({
    filters,
    value,
    onSelect,
  }: {
    filters?: FilterType[];
    value: number | null;
    onSelect?: (value: number) => void;
  }) => {
    const handleChange = (event: SelectChangeEvent<number>) => {
      if (onSelect) {
        onSelect(parseInt(event.target.value.toString()));
      }
    };

    return (
      <>
        {filters && value !== null && (
          <FormControl size="small" sx={{ minWidth: 120 }}>
            <InputLabel sx={{ fontSize: 13 }}>Vis for</InputLabel>
            {/* dynamic filter options except HoursFromNow = 0 */}
            <Select
              value={value}
              onChange={handleChange}
              sx={{
                borderRadius: "5px",
                backgroundColor: "#ffffff",
                fontSize: 14,
              }}
              label="Bruger"
              displayEmpty={true}
            >
              {filters.map((data, i) => (
                <MenuItem key={i} value={data.HoursFromNow}>
                  {data.Name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        )}
      </>
    );
  }
);

const KpiItem = memo(({ data }: { data: KpiType }) => {
  const value: number = data.activityboard_kpi_values[0]
    ? data.activityboard_kpi_values[0].Value
    : 0;
  const deltaValue: number = data.activityboard_kpi_values[0]
    ? data.activityboard_kpi_values[0].DeltaValue
    : 0;
  const deltaColor =
    deltaValue > 0 ? "#81DB94" : deltaValue < 0 ? "#E98484" : "#777";

  return (
    <Box>
      {/* title */}
      <Typography mb={0.5} fontSize={12}>
        {data.Name}
      </Typography>
      <Stack direction="row" alignItems="flex-start">
        {/* value */}
        <Typography
          fontWeight={700}
          fontSize={{ md: 36, xs: 28 }}
          lineHeight={1}
        >
          {value}
        </Typography>
        {/* delta value */}
        <Stack direction="row" ml={0.75} mt={0.5} alignItems="center">
          {/* arrow and color */}
          {deltaValue > 0 ? (
            <ArrowUpwardRounded
              sx={{ fontSize: 16, lineHeight: 1, color: deltaColor }}
            />
          ) : deltaValue < 0 ? (
            <ArrowDownwardRounded
              sx={{ fontSize: 16, lineHeight: 1, color: deltaColor }}
            />
          ) : (
            <></>
          )}
          <Typography
            component="span"
            fontSize={14}
            fontWeight={600}
            lineHeight={1}
            color={deltaColor}
          >
            {deltaValue}
          </Typography>
        </Stack>
      </Stack>
    </Box>
  );
});

const KpiItemSkeleton = () => {
  return (
    <Box>
      {/* title */}
      <Skeleton
        width={60}
        sx={{
          mb: 0.5,
        }}
      />
      <Stack direction="row" alignItems="flex-start">
        {/* value */}
        <Skeleton variant="rectangular" width={50} height={36} />
        {/* delta value */}
        <Box ml={0.75}>
          <Skeleton variant="rectangular" width={15} height={15} />
        </Box>
      </Stack>
    </Box>
  );
};

export default Kpi;
