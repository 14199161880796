import { gql, useLazyQuery } from "@apollo/client";
import {
  Box,
  FormControl,
  MenuItem,
  Select,
  SelectChangeEvent,
} from "@mui/material";
import {
  CategoryScale,
  Chart as ChartJS,
  ChartData,
  ChartOptions,
  Legend,
  LinearScale,
  LineElement,
  PointElement,
  Tooltip,
} from "chart.js";
import React, { useEffect, useState } from "react";
import { Line } from "react-chartjs-2";
import { Panel, PanelSubTitle, PanelTitle } from "../../../../components/Panel";
import { useData } from "../../context/data";

// === gql
export const QUERY_STATISTICS = gql`
  query Economy($type: ID) {
    statistics {
      Economy(type: $type) {
        Money
        Total
      }
      ActorDistribution {
        Roles {
          Role
          Total
        }
        Total
      }
    }
  }
`;

// === types
type EconomyData = {
  Money: string;
  Total: number;
};

type Statistics = {
  Economy: EconomyData[];
};

type ChartDataType = {
  value: number;
  label: string;
  tooltip: string;
};

const Economy = () => {
  const { economy: economyOptions } = useData();
  const [selectedType, setSelectedType] = useState<string | null>(null);
  const [chartData, setChartData] = useState<ChartDataType[]>([]);
  const [total, setTotal] = useState(0);

  // gql
  const [getData, { data: dataStatistics }] = useLazyQuery<
    { statistics: Statistics },
    { type: number }
  >(QUERY_STATISTICS);

  // set the first option as the selected type
  useEffect(() => {
    if (economyOptions.length > 0) {
      setSelectedType(economyOptions[0].ID);
    }
  }, [economyOptions]);

  // fetch data when selected type changed and not null
  useEffect(() => {
    if (selectedType !== null) {
      getData({
        variables: {
          type: Number(selectedType),
        },
      });
    }
  }, [selectedType, getData]);

  useEffect(() => {
    if (dataStatistics?.statistics) {
      const economyData = dataStatistics?.statistics.Economy;
      setChartData(
        economyData.map<ChartDataType>((data) => {
          return {
            value: data.Total,
            label: data.Money.replace("kr./ mdr.", "").replace("kr./ mdr", ""),
            tooltip: data.Money,
          };
        })
      );

      // set total
      let tot = 0;
      economyData.forEach((data) => {
        tot += data.Total;
      });
      setTotal(tot);
    }
  }, [dataStatistics]);

  const handleUpdate = (event: SelectChangeEvent) => {
    setSelectedType(event.target.value as string);
  };

  return (
    <Panel
      header={
        <>
          <PanelTitle mb={0.5}>Økonomi hos aktører</PanelTitle>
          <PanelSubTitle>Baseret på {total} aktører</PanelSubTitle>
        </>
      }
      actions={
        <>
          <Box sx={{ minWidth: 120 }}>
            {selectedType !== null && economyOptions.length > 0 && (
              <FormControl fullWidth>
                <Select value={selectedType} onChange={handleUpdate}>
                  {economyOptions.map((option) => (
                    <MenuItem key={option.ID} value={option.ID}>
                      {option.Name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            )}
          </Box>
        </>
      }
    >
      <LineChart data={chartData} />
    </Panel>
  );
};

ChartJS.register(
  Tooltip,
  Legend,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement
);

const LineChart = ({ data }: { data: ChartDataType[] }) => {
  const [chartData, setChartData] = useState<ChartData<"line", number[]>>({
    datasets: [],
    labels: [],
  });

  const [options, setOptions] = useState<ChartOptions<"line">>({});

  useEffect(() => {
    setChartData({
      labels: data.map((d) => d.label),
      datasets: [
        {
          data: data.map((d) => d.value),
          backgroundColor: "#496A70",
          borderColor: "#496A70",
          tension: 0.3,
        },
      ],
    });
  }, [data]);

  useEffect(() => {
    setOptions({
      //   maintainAspectRatio: false,
      aspectRatio: 3,
      scales: {
        xAxis: {
          grid: {
            color: "#F4F5F5",
          },
        },
        yAxis: {
          beginAtZero: true,
          grid: {
            color: "#F4F5F5",
            borderDash: [8, 4],
          },
        },
      },
      plugins: {
        legend: {
          display: false,
        },
        tooltip: {
          backgroundColor: "rgba(255,255,255,1)",
          padding: 15,
          titleColor: "#000",
          bodyColor: "#000",
          bodyFont: {
            size: 14,
            lineHeight: 1.4,
          },
          footerColor: "#000",
          borderColor: "rgba(0,0,0,0.1)",
          borderWidth: 1,
          displayColors: false,
          callbacks: {
            label: (context) => {
              const ind = context.dataIndex;
              const total = Number(context.dataset.data[ind]);
              const totaLabel = `${total} ${
                total > 1 ? "aktører" : "aktør"
              } matcher`;
              const label = data[ind].tooltip;
              return [label, totaLabel];
            },
            title: (context) => {
              return "";
            },
          },
        },
      },
    });
  }, [chartData, data]);

  return <Line options={options} data={chartData} />;
};

export default Economy;
