import { TypographyOptions } from "@mui/material/styles/createTypography";

const typography: TypographyOptions = {
  fontFamily: [
    "Inter",
    "-apple-system",
    "BlinkMacSystemFont",
    '"Segoe UI"',
    "Roboto",
    '"Helvetica Neue"',
    "Arial",
    "sans-serif",
    '"Apple Color Emoji"',
    '"Segoe UI Emoji"',
    '"Segoe UI Symbol"',
  ].join(","),
  fontSize: 14,
  fontWeightLight: 300,
  fontWeightRegular: 400,
  fontWeightMedium: 500,
  fontWeightBold: 600,
  h2: {
    fontWeight: 600,
    fontSize: 32,
  },
  h3: {
    fontWeight: 600,
    fontSize: 22,
  },
  h4: {
    fontWeight: 600,
    fontSize: 18,
  },
  h5: {
    fontWeight: 600,
    fontSize: 14,
  },
  body1: {
    fontSize: 14,
  },
  body2: {
    fontSize: 14,
  },
  sectionTitle: {
    fontWeight: 600,
    fontSize: 14,
  },
  sectionDesc: {
    fontWeight: 400,
    fontSize: 12,
    color: "#888888",
  },
};

export default typography;

declare module "@mui/material/styles" {
  interface TypographyVariants {
    sectionTitle: React.CSSProperties;
    sectionDesc: React.CSSProperties;
  }

  interface TypographyVariantsOptions {
    sectionTitle?: React.CSSProperties;
    sectionDesc?: React.CSSProperties;
  }
}

declare module "@mui/material/Typography" {
  interface TypographyPropsVariantOverrides {
    sectionTitle: true;
    sectionDesc: true;
  }
}
