import { gql, useLazyQuery, useMutation } from "@apollo/client";
import { useCallback } from "react";
import { useActivitiesDispatch, useActivityFilter } from "./context";
import { Activity, ActivityTypeString } from "./types";

// === gql.
const GET_ACTIVITIES = gql`
  query Events($limit: Int, $offset: Int, $type: String) {
    events(limit: $limit, offset: $offset, type: $type) {
      ABE_ID
      Description
      B_ID
      Actor {
        SYS_A_ID
        Firstname
        Lastname
      }
      Status
      Type
      EventTime
      Seen
      activityboard_event_type {
        Name
        ColorInfo
        ColorWarning
        ColorAlert
        CanBeAssigned
      }
      user {
        U_ID
        Navn
      }
    }
  }
`;

const MUTATION_SEEN = gql`
  mutation Mutation($abeId: ID, $seen: Boolean) {
    seenEvent(ABE_ID: $abeId, Seen: $seen)
  }
`;

const MUTATION_SEEN_ALL = gql`
  mutation SeenAllEvents($seen: Boolean) {
    seenAllEvents(Seen: $seen)
  }
`;

const MUTATION_ARCHIVE = gql`
  mutation ArchiveEvent($abeId: ID, $archived: Boolean) {
    archiveEvent(ABE_ID: $abeId, Archived: $archived)
  }
`;

const MUTATION_ARCHIVE_ALL = gql`
  mutation ArchiveAllEvents($archived: Boolean) {
    archiveAllEvents(Archived: $archived)
  }
`;

const MUTATION_UNDO = gql`
  mutation Mutation {
    undoArchive
  }
`;

type FetchDataType = {
  limit?: number;
  offset?: number;
  isInsert?: boolean;
  type?: ActivityTypeString | null;
};

export const useGetActivities = () => {
  const { dispatch } = useActivitiesDispatch();
  const { type } = useActivityFilter();

  const [getData, { loading }] = useLazyQuery<
    { events?: Activity[] },
    { offset: number; limit: number; type: ActivityTypeString | null }
  >(GET_ACTIVITIES);

  const fetchData = useCallback(
    (params?: FetchDataType) => {
      if (!params?.isInsert) {
        dispatch({ type: "loading" });
      }
      getData({
        variables: {
          offset: params?.offset ? params.offset : 0,
          limit: params?.limit ? params.limit : 5,
          type: params?.type ? params.type : type,
        },
      }).then((res) => {
        if (res?.data?.events) {
          if (params?.isInsert) {
            dispatch({ type: "insertMore", activities: res?.data?.events });
          } else {
            dispatch({ type: "reload", activities: res?.data?.events });
          }
        } else {
          dispatch({ type: "reload", activities: [] });
        }
      });
    },
    [dispatch, getData, type]
  );

  return { fetchData, loading };
};

export const useUpdateActivity = () => {
  const { dispatch } = useActivitiesDispatch();

  // === SEEN
  const [seenMutation] = useMutation<
    { seenEvent: any },
    { abeId: string; seen: boolean }
  >(MUTATION_SEEN);

  const seen = (id: string) => {
    return seenMutation({
      variables: {
        abeId: id,
        seen: true,
      },
    }).then((res) => {
      dispatch({ type: "update", activity: { ABE_ID: id, Seen: true } });
      return res;
    });
  };

  // === SEEN ALL
  const [seenAllMutation] = useMutation<
    { seenAllEvents: boolean },
    { seen: boolean }
  >(MUTATION_SEEN_ALL);

  const seenAll = (value = true) => {
    return seenAllMutation({
      variables: {
        seen: value,
      },
    }).then(() => {
      dispatch({ type: "seenAll", seen: value });
    });
  };

  // === ARCHIVE
  const [archiveMutation] = useMutation<
    { archiveEvent: any },
    { abeId: string; archived: boolean }
  >(MUTATION_ARCHIVE);

  const archive = (id: string) => {
    return archiveMutation({
      variables: {
        abeId: id,
        archived: true,
      },
    }).then((res) => {
      dispatch({ type: "remove", id });
      return res;
    });
  };

  // === ARCHIVE ALL
  const [archiveAllMutation] = useMutation<
    { archiveAllEvents: boolean },
    { archived: boolean }
  >(MUTATION_ARCHIVE_ALL);

  const archiveAll = () => {
    return archiveAllMutation({
      variables: {
        archived: true,
      },
    }).then(() => {
      dispatch({ type: "reload", activities: [] });
    });
  };

  // === UNDO
  const [undoMutation] = useMutation<{ undoArchive: boolean }>(MUTATION_UNDO);

  const undo = () => {
    dispatch({ type: "loading" });
    return undoMutation();
  };

  return { seen, seenAll, archive, archiveAll, undo };
};
