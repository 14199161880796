import { Box, Divider, Grid, Typography } from "@mui/material";
import { FormikProps } from "formik";
import React, { useCallback, useEffect } from "react";
import {
  FieldCity,
  FieldCountry,
  FieldEmail,
  FieldFirstName,
  FieldFloor,
  FieldHouseNumber,
  FieldLastName,
  FieldPhone,
  FieldSideDoor,
  FieldStreet,
  FieldZip,
  InactiveActive,
  UserName,
} from "../MasterDataFields";
import { FormValues } from "./types";

const FormAdd = ({ formik }: { formik: FormikProps<FormValues> }) => {
  // reset when mounted.
  useEffect(() => {
    formik.resetForm();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleFirstName = useCallback((e: React.ChangeEvent<any>) => {
    formik.setFieldValue("firstName", e.target.value);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleLastName = useCallback((e: React.ChangeEvent<any>) => {
    formik.setFieldValue("lastName", e.target.value);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleUsername = useCallback((e: React.ChangeEvent<any>) => {
    formik.setFieldValue("username", e.target.value);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handlePhone = useCallback((e: React.ChangeEvent<any>) => {
    formik.setFieldValue("phone", e.target.value);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleEmail = useCallback((e: React.ChangeEvent<any>) => {
    formik.setFieldValue("email", e.target.value);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleStreet = useCallback((e: React.ChangeEvent<any>) => {
    formik.setFieldValue("street", e.target.value);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleHouseNumber = useCallback((e: React.ChangeEvent<any>) => {
    formik.setFieldValue("houseNumber", e.target.value);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleFloor = useCallback((e: React.ChangeEvent<any>) => {
    formik.setFieldValue("floor", e.target.value);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSideDoor = useCallback((e: React.ChangeEvent<any>) => {
    formik.setFieldValue("sideDoor", e.target.value);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleZip = useCallback((e: React.ChangeEvent<any>) => {
    formik.setFieldValue("zip", e.target.value);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleCity = useCallback((e: React.ChangeEvent<any>) => {
    formik.setFieldValue("city", e.target.value);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleCountry = useCallback((value: string | null) => {
    formik.setFieldValue("country", value);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleActive = useCallback(
    (checked: boolean) => {
      formik.setFieldValue("active", checked);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const handleAutoMatch = useCallback(
    (checked: boolean) => {
      formik.setFieldValue("autoMatch", checked);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  return (
    <div>
      <Box py={3.5} px={3}>
        <Box mb={3}>
          <Typography variant="sectionTitle">Kontaktinformationer</Typography>
        </Box>
        <Grid container columnSpacing={2} rowSpacing={3}>
          <Grid item xs={12} sm={6} md={3}>
            <FieldFirstName
              name="firstName"
              value={formik.values.firstName}
              onChange={handleFirstName}
              error={
                formik.touched.firstName && Boolean(formik.errors.firstName)
              }
              helperText={formik.touched.firstName && formik.errors.firstName}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <FieldLastName
              name="lastName"
              value={formik.values.lastName}
              onChange={handleLastName}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={3} lg={2}>
            <FieldPhone
              name="phone"
              value={formik.values.phone}
              onChange={handlePhone}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <FieldEmail
              name="email"
              value={formik.values.email}
              onChange={handleEmail}
              error={formik.touched.email && Boolean(formik.errors.email)}
              helperText={formik.touched.email && formik.errors.email}
            />
          </Grid>
        </Grid>
      </Box>

      <Divider />

      <Box py={3.5} px={3}>
        <Box mb={2}>
          <Typography variant="sectionTitle">Adresse</Typography>
        </Box>
        <Grid container columnSpacing={2} rowSpacing={2}>
          <Grid item xs={12} sm={6} md={3}>
            <FieldStreet
              name="street"
              value={formik.values.street}
              onChange={handleStreet}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={3} lg={2}>
            <FieldHouseNumber
              name="houseNumber"
              value={formik.values.houseNumber}
              onChange={handleHouseNumber}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={3} lg={2}>
            <FieldFloor
              name="floor"
              value={formik.values.floor}
              onChange={handleFloor}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={3} lg={2}>
            <FieldSideDoor
              name="sideDoor"
              value={formik.values.sideDoor}
              onChange={handleSideDoor}
            />
          </Grid>
          <Grid item xs={12} />
          <Grid item xs={12} sm={6} md={3}>
            <FieldCity
              name="city"
              value={formik.values.city}
              onChange={handleCity}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={3} lg={2}>
            <FieldZip
              name="zip"
              value={formik.values.zip}
              onChange={handleZip}
            />
          </Grid>
          <Grid item xs={12} />
          <Grid item xs={12} sm={6} md={3}>
            <FieldCountry
              name="country"
              value={formik.values.country}
              onChange={handleCountry}
            />
          </Grid>
        </Grid>
      </Box>
      <Box py={3.5} px={3}>
        <Grid container columnSpacing={3} justifyContent="space-between">
          <Grid item md={4} lg={3} xs={12}>
            <Box mb={{ md: 0, xs: 2 }}>
              <Typography variant="sectionTitle" mb={1}>
                Medlemsportal
              </Typography>
            </Box>
          </Grid>
          <Grid item md={6} lg={7} xs={12}>
            <Box>
              <UserName
                name="username"
                value={formik.values.username}
                onChange={handleUsername}
                fullWidth={false}
              />
            </Box>
          </Grid>
        </Grid>
      </Box>

      <InactiveActive
        portal={formik.values.active}
        autoMatch={formik.values.autoMatch}
        onChangePortal={handleActive}
        onChangeAutoMatch={handleAutoMatch}
      />
    </div>
  );
};

export default FormAdd;
