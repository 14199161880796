import {
  Checkbox,
  FormControl,
  InputLabel,
  ListItemText,
  MenuItem,
  Select,
  SelectChangeEvent,
} from "@mui/material";
import React, { memo } from "react";
import { useData } from "../../../context/data";

const FieldSelectSellers = ({
  selected,
  updateSelected,
}: {
  selected: string[];
  updateSelected: (selected: string[]) => void;
}) => {
  const { users } = useData();

  const handleChange = (event: SelectChangeEvent<typeof selected>) => {
    const {
      target: { value },
    } = event;
    updateSelected(typeof value === "string" ? value.split(",") : value);
  };

  return (
    <FormControl fullWidth>
      <InputLabel>Vælg ansvarlig</InputLabel>
      <Select
        value={selected}
        label="Vælg ansvarlig"
        onChange={handleChange}
        multiple
        renderValue={(selected) =>
          users
            .reduce<string[]>((filtered, option) => {
              if (selected.includes(option.U_ID)) {
                filtered.push(option.Navn);
              }
              return filtered;
            }, [])
            .join(", ")
        }
        MenuProps={{
          PaperProps: {
            sx: {
              maxHeight: 250,
              width: 380,
            },
          },
          anchorOrigin: {
            vertical: "bottom",
            horizontal: "left",
          },
          transformOrigin: {
            vertical: "top",
            horizontal: "left",
          },
        }}
      >
        {users.map((user, i) => (
          <MenuItem key={user.U_ID} value={user.U_ID}>
            <Checkbox checked={selected.includes(user.U_ID)} />
            <ListItemText primary={user.Navn} />
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default memo(FieldSelectSellers);
