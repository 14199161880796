import {
  LinearProgress,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
} from "@mui/material";
import { memo, useCallback } from "react";
import { StyledTable } from "../../../../../theme/styled-components";
import { useActors } from "../../../context/actors";
import { useSearch } from "../../../context/search";
import { useSearchActors } from "../../../context/search-actors";
import ActorTableRow from "./ActorTableRow";

const ActorTable = () => {
  return (
    <TableContainer
      component={"div"}
      sx={{ position: "relative", px: 2.5, py: 1.5 }}
    >
      <Loading />
      <StyledTable>
        <TableHead>
          <TableRow>
            <TableCell>
              <ActorsTableSort sortBy="id">ID / Dato</ActorsTableSort>
            </TableCell>
            <TableCell>
              <ActorsTableSort sortBy="name">Kontakt</ActorsTableSort>
            </TableCell>
            <TableCell>Status</TableCell>
            <TableCell>
              <ActorsTableSort sortBy="autoMatch">
                Auto matching
              </ActorsTableSort>
            </TableCell>
            <TableCell>
              <ActorsTableSort sortBy="matchedUnits">Matches</ActorsTableSort>
            </TableCell>
            <TableCell>
              <ActorsTableSort sortBy="matchedUnitsUnseen">
                Uset(e) bolig(er)
              </ActorsTableSort>
            </TableCell>
            <TableCell>Præferencer</TableCell>
            <TableCell>Ansvarlig</TableCell>
            <TableCell></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          <ActorsTableBody />
        </TableBody>
      </StyledTable>
    </TableContainer>
  );
};

const Loading = () => {
  const { loading } = useSearchActors();

  return (
    <>
      {loading && (
        <LinearProgress
          sx={{ position: "absolute", left: 0, top: 0, width: "100%" }}
        />
      )}
    </>
  );
};

const ActorsTableSort: React.FC<{ sortBy: string }> = ({
  sortBy,
  children,
}) => {
  const { submit, params } = useSearch();

  const handleSort = useCallback(() => {
    submit({
      sortBy,
      sort:
        params.sortBy === sortBy
          ? params.sort === "ASC"
            ? "DESC"
            : "ASC"
          : params.sort,
    });
  }, [params, sortBy, submit]);

  return (
    <TableSortLabel
      onClick={handleSort}
      active={sortBy === params.sortBy}
      direction={params.sort === "ASC" ? "asc" : "desc"}
    >
      {children}
    </TableSortLabel>
  );
};

const ActorsTableBody = () => {
  // get state from context.
  const { actors } = useActors();

  return (
    <>
      {actors?.map((actor) => (
        <ActorTableRow key={actor.SYS_A_ID} actor={actor} />
      ))}
    </>
  );
};

export default memo(ActorTable);
