import { MoreVert } from "@mui/icons-material";
import {
  Chip,
  Divider,
  IconButton,
  Menu,
  MenuItem,
  TableCell,
  TableRow,
  Typography,
} from "@mui/material";
import { format, parseISO } from "date-fns";
import React, { memo } from "react";
import { useEditUser } from "../../../context/edit-user";
import { Actor } from "../../../types";
import Preferences from "./Preferences";
import Seller from "./Seller";
import Status from "./Status";
import { useEdit } from "../../../context/edit";

const ActorTableRow = ({ actor }: { actor: Actor }) => {
  return (
    <TableRow>
      <TableCell>
        <Typography fontSize={13} fontWeight={500} component="div">
          #{actor.SYS_A_ID}
        </Typography>
        <Typography fontSize={11} component="div">
          {actor.Created && format(parseISO(actor.Created), "MMMM d, yyyy")}
        </Typography>
      </TableCell>
      <TableCell>
        <Typography fontSize={13} fontWeight={500} component="div">
          {actor.Firstname} {actor.Lastname}
        </Typography>
        <Typography fontSize={11} component="div">
          <div>
            {actor.Street} {actor.HouseNo}
          </div>
          <div>
            {actor.ZipCode} {actor.City}
          </div>
          {actor.PhonePrivate}
        </Typography>
      </TableCell>
      <TableCell>
        <Status activities={actor.Activity ?? []} />
      </TableCell>
      <TableCell>
        <Chip
          label={actor.AutoMatch ? "SLÅET TIL" : "SLÅET FRA"}
          size="small"
          sx={{
            backgroundColor: "#F5D8D2",
          }}
        />
      </TableCell>
      <TableCell>
        <Chip
          label={`${Number(actor.MatchedUnitsCount).toString()} ${
            Number(actor.MatchedUnitsCount) > 1 ? "boliger" : "bolig"
          }`}
          size="small"
          sx={{
            backgroundColor: "#F5D8D2",
          }}
        />
      </TableCell>
      <TableCell>
        <Chip
          label={`${Number(actor.UnseenUnits).toString()} ${
            Number(actor.UnseenUnits) > 1 ? "boliger" : "bolig"
          }`}
          size="small"
          sx={{
            backgroundColor: "#F5D8D2",
          }}
        />
      </TableCell>
      <TableCell>
        <Preferences answers={actor.Answers ?? []} />
      </TableCell>
      <TableCell>
        <Seller userId={actor.U_ID} />
      </TableCell>
      <TableCell align="right">
        <ActorMenu key={actor.SYS_A_ID} actor={actor} />
      </TableCell>
    </TableRow>
  );
};

const ActorMenu: React.FC<{ actor: Actor }> = memo(({ actor }) => {
  const { open: openEdit } = useEdit();

  // edit user context.
  const { open: openEditUser } = useEditUser();

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleEdit = () => {
    handleClose();
    openEdit(actor.SYS_A_ID);
  };

  const handleEditUser = () => {
    handleClose();
    openEditUser(actor);
  };

  return (
    <>
      <IconButton onClick={handleClick}>
        <MoreVert />
      </IconButton>
      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      >
        <MenuItem onClick={handleEdit}>Rediger data</MenuItem>
        <Divider sx={{ my: 0 }} />
        <MenuItem onClick={handleEditUser}>Tilføj ansvarlig</MenuItem>
      </Menu>
    </>
  );
});

export default memo(ActorTableRow);
