import { Add } from "@mui/icons-material";
import { Button } from "@mui/material";
import { useAddActor } from "../../context/add";

const AddButton = () => {
  const { add } = useAddActor();
  return (
    <Button onClick={add} variant="contained" startIcon={<Add />}>
      OPRET NY AKTØR
    </Button>
  );
};

export default AddButton;
